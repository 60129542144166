// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

type Props = {
  className: string,
  onClick: () => void,
};

const IconCamera = (props: Props): React$Node => {
  const {
    className,
    onClick,
  } = props;

  const iconBurgerMenuClassName = useMemo((): string => clsx({
    'icon': true,
    [String(className)]: !!className,
  }), [className]);

  const newProps = {
    className: iconBurgerMenuClassName,
    onClick,
  };

  return (
    <svg
      fill="#000000"
      width="80px"
      height="80px"
      viewBox="-4 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      { ...newProps }
    >
      <path
        d="M15.5 13.219l6.844-3.938c0.906-0.531 1.656-0.156 1.656 0.938v11.625c0 1.063-0.75 1.5-1.656 0.969l-6.844-3.969v2.938c0 1.094-0.875 1.969-1.969 1.969h-11.625c-1.063 0-1.906-0.875-1.906-1.969v-11.594c0-1.094 0.844-1.938 1.906-1.938h11.625c1.094 0 1.969 0.844 1.969 1.938v3.031z"></path>
    </svg>
  );
};

IconCamera.defaultProps = {
  className: 'icon',
  onClick: () => {
  },
};

export default IconCamera;
