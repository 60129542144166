// @flow
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/dist/moment';

import type { GroupClassDetailed } from '@groupClass/types';
import type { Coach } from '@coach/types';

import withGroupClass from '@hoc/withGroupClass';

import ButtonGoBack from '@shared/Navigation/ButtonGoBack';
import IconCalendar from '@icons/components/IconCalendar';
import IconMarkerMap from '@icons/components/IconMarkerMap';
import PreRegister from '@login/components/PreRegister';

import DateService from '_common/services/DateService';

import { WEB_PATHS } from '@app/constants/paths';
import { FRANCE, SPAIN_INDICATIVE_PRICE } from '@app/constants/constants';

const { PAGE_NOT_FOUND } = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailed | null,
};

const GroupClassVisitor = (props: Props): React$Node => {
  const { groupClass } = props;

  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (groupClass && groupClass?.seasonClosedAt) {
      navigate(PAGE_NOT_FOUND);
    }
  }, [groupClass]);

  const startDate = useMemo((): string => (
    moment(groupClass?.startDate).locale(language).format('L')
  ), [groupClass, language]);

  const endDate = useMemo((): string => (
    moment(groupClass?.endDate).locale(language).format('L')
  ), [groupClass, language]);

  const startTime = useMemo((): string => (
    groupClass
      ? DateService.localDateAndTimeDisplay(groupClass.day, groupClass.startTime, groupClass.endTime, language)
      : ''
  ), [groupClass, language]);

  const addressGroupClass = useMemo((): string => (
    groupClass
      ? `${ groupClass.address.streetName } ${ groupClass.address.additionalLocalizationConstruction ?? '' } ${ groupClass.address.postalCode } ${ groupClass.address.city }`
      : ''
  ), [groupClass]);

  const mainCoach = useMemo((): Coach | null => (
    groupClass?.coaches.find((coach) => coach.isMainCoach) ?? null
  ), [groupClass]);

  const mainCoachName = useMemo((): string => (
    mainCoach?.sielBleuUser.firstName || ''
  ), [mainCoach]);

  const indicativePrice = groupClass?.sector.country === FRANCE
    ? groupClass?.activity.indicativePrice
    : SPAIN_INDICATIVE_PRICE;

  return (
    <div className="group-class-card-container container">
      <div className="group-class-card-header">
        <div className="back-btn">
          <ButtonGoBack />
        </div>
        <div className="faq-toggle-btn">
          <a key={ t('user.faq') } target="_blank" rel="noreferrer" href={ t('user.faq.link') }>{ t('user.faq') }</a>
        </div>
      </div>

      { groupClass && (
        <div className="group-class-card">
          <div className="header">
            <div>
              <h2 className="name">{ groupClass?.activity.name }</h2>
              { mainCoach && (
                <p className="coach">
                  { t('common.with') } <span><u>{ mainCoachName }</u></span>
                </p>
              ) }
            </div>
          </div>

          <div className="group-class-card-content">
            <div>
              <div className="infos">
                <div className="infos-datetime">
                  <IconCalendar />
                  <span>{ startTime }</span>
                </div>
                { !groupClass?.isOnline && (
                  <div className="infos-address">
                    <IconMarkerMap />
                    <span>{ addressGroupClass }</span>
                  </div>
                ) }
              </div>
            </div>
            <div className="group-class-card-content-block">
              <h2 className="group-class-card-title">
                { t('common.description') }
              </h2>
              <span>
                { groupClass.description }
              </span>
            </div>
            <div className="block-preregister-prices group-class-card-content-block">
              <div className="price-block">
                <h2 className="group-class-card-title no-margin-top">
                  { t('groupClasses.groupClass.detailed.indicativePrices.title') }
                </h2>
                <span>{ t('groupClasses.groupClass.detailed.indicativePrices.price', { indicativePrice }) }</span>
                { groupClass?.sector.country === FRANCE ? (
                  <ul className="price">
                    <li>{ t('groupClasses.groupClass.detailed.indicativePrices.firstLine') }</li>
                    <li>{ t('groupClasses.groupClass.detailed.indicativePrices.secondLine') }</li>
                    <li>{ t('groupClasses.groupClass.detailed.indicativePrices.thirdLine') }</li>
                  </ul>
                ) : null }
              </div>

              <div className="preregister-block">
                <PreRegister groupClass={ groupClass } />
                <span className="first-time-preregister-text">
                  { t('groupClasses.groupClass.detailed.firstTimePreregister') }
                </span>
              </div>
            </div>
            <div className="group-class-card-content-block">
              <h2 className="group-class-card-title">
                { t('groupClasses.groupClass.detailed.season') }
              </h2>
              <span>
                { t('dates.from') } { startDate }
                &nbsp;
                { t('dates.to') } { endDate }
                <br />
                { groupClass?.exceptDates && (
                  <span>
                    { t('groupClasses.groupClass.detailed.exceptDate') } { groupClass.exceptDates }
                  </span>
                ) }
              </span>
            </div>
            { groupClass?.registrationTerms && (
              <div className="group-class-card-content-block">
                <h2 className="group-class-card-title">
                  { t('groupClasses.groupClass.detailed.registrationTerm') }
                </h2>
                <span>
                  { groupClass.registrationTerms }
                </span>
              </div>
            ) }
            { groupClass?.registrationContact && (
              <div className="group-class-card-content-block">
                <h2 className="group-class-card-title">
                  { t('groupClasses.groupClass.detailed.registrationContact') }
                </h2>
                <span>
                  { groupClass.registrationContact }
                </span>
              </div>
            ) }
          </div>
        </div>
      ) }
    </div>
  );
};

export default withGroupClass(GroupClassVisitor);
