// @flow

export const WEB_PATHS = {
  HOME: '/home',
  GROUP_CLASSES: '/group-classes',
  GROUP_CLASSES_BENEFICIARY: '/group-classes/beneficiary',
  GROUP_CLASSES_TO_VALIDATE: '/group-classes/to-validate',
  GROUP_CLASSES_SEASON_CLOSED: '/group-classes/season-closed',
  GROUP_CLASS: '/group-class/:classId',
  GROUP_CLASS_EDIT: '/group-class/:classId/edit',

  LOGIN: '/login',
  RESET_KEY_REQUEST: '/reset-key-request',
  SIEL_BLEU_USER_PASSWORD_UPDATE: '/siel-bleu-user/password-update',
  LOGIN_RETURN_SINGLE_SIGN_ON: '/login-return',
  LOGOUT_RETURN_SINGLE_SIGN_ON: '/logout-return',
  SERVICE_UNAVAILABLE: '/service-unavailable',

  PARTICIPANTS: '/group-class/:classId/participants',
  PARTICIPANTS_SEARCH: '/group-class/:classId/participants/search',
  PARTICIPANTS_CREATE_PARTICIPANT: '/group-class/:classId/participants/create',
  PARTICIPANTS_ADD_PERSON_BY_ID_PERSON: '/group-class/:classId/participants/add/:personId/:personType',
  PARTICIPANTS_ADD_PERSON_BY_ID_PAYMENT: '/group-class/:classId/participants/add/:personId/:personType/payment',
  PARTICIPANTS_CLASS_REQUEST_PERSON: '/group-class/:classId/participants/request/:requestId',
  PARTICIPANTS_CLASS_REQUEST_PAYMENT: '/group-class/:classId/participants/request/:requestId/payment',
  PARTICIPANTS_REGISTERED_ID: '/group-class/:classId/participants/registered/:registeredId',
  PARTICIPANTS_REGISTERED_EDIT_PERSON: '/group-class/:classId/participants/registered/:registeredId/person',
  PARTICIPANTS_REGISTERED_EDIT_PAYMENT: '/group-class/:classId/participants/registered/:registeredId/payment',

  PEOPLE: '/people',
  PEOPLE_DETAILS: '/people/:peopleId/:peopleType',
  PEOPLE_EDIT: '/people/:peopleId/:peopleType/edit',

  PAYMENTS: '/group-class/:classId/payments',
  PAYMENTS_CREATE_CHEQUE: '/group-class/:classId/payments/create-cheque',
  PAYMENTS_PAYMENTS_DETAILED: '/group-class/:classId/payments/detailed',
  PAYMENTS_DETAILED_CREDITCARD_OR_CHEQUE: '/group-class/:classId/payments/detailed/:paymentType',
  PAYMENTS_BANK_DEPOSITS: '/group-class/:classId/payments/deposits',
  PAYMENTS_BANK_DEPOSITS_ADD: '/group-class/:classId/payments/deposits/add',
  PAYMENTS_BANK_DEPOSIT_VIEW: '/group-class/:classId/payments/deposit/:depositId',
  PAYMENTS_BANK_DEPOSIT_ADD_CHEQUES: '/group-class/:classId/payments/reference/:reference/deposit-date/:depositDate/add-cheques',
  PAYMENTS_DEPOSIT_EDIT: '/group-class/:classId/payments/deposit/:depositId/edit',
  PAYMENTS_CHEQUE_EDIT: '/group-class/:classId/payments/cheque/:chequeId/edit',

  GROUP_SEND: '/group-class/:classId/group-send',
  GROUP_SEND_EMAIL: '/group-class/:classId/group-send/email',
  GROUP_SEND_SMS: '/group-class/:classId/group-send/sms',
  GROUP_SEND_SHARED_SESSION: '/group-class/:classId/group-send/shared-session',

  SHARED_SESSIONS: '/shared-sessions',
  BENEFICIARY_PAYMENT: '/beneficiary-payment',
  INVOICE_DETAILS: '/invoice-details',

  MONETICO_ERROR: '/monetico-error',
  MONETICO_SUCCESS: '/monetico-success',

  PAGE_CONTACT: '/contact',
  PAGE_NOT_FOUND: '/page-not-found',
};

export const API_PATHS = {
  /* Activity */
  ACTIVITIES: '/activities',
  /* Beneficiary */
  BENEFICIARIES: '/beneficiaries',
  GROUP_CLASS_REQUESTS_BENEFICIARY: '/beneficiaries/:id/group_class_requests',
  GROUP_CLASS_REGISTEREDS_BENEFICIARY: '/beneficiaries/:id/group_class_registereds',
  BENEFICIARY_BALANCES: '/beneficiaries/:id/balances',
  /* Participant */
  PARTICIPANTS: '/participants',
  GROUP_CLASS_REGISTEREDS_PARTICIPANT: '/participants/:id/group_class_registereds',
  /* GroupClassRegistered */
  GROUP_CLASS_REGISTEREDS: '/group_classes/:id/group_class_registereds',
  GROUP_CLASS_REGISTERED: '/group_class_registereds',
  /* GroupClassRequest */
  GROUP_CLASS_REQUESTS: '/group_classes/:id/group_class_requests',
  GROUP_CLASS_REQUEST: '/group_class_requests',
  /* GroupClass */
  GROUP_CLASSES: '/group_classes',
  GROUP_CLASSES_COORDINATES: '/group_classes/coordinates',
  GROUP_CLASSES_ARCHIVE: '/group_class/:classId/archive',
  GROUP_CLASSES_EXPORT: '/group_classes/export',
  /* GroupClassPeriod */
  GROUP_CLASS_PERIODS: '/group_class_periods',
  /* GroupClassPeriodsArchive */
  GROUP_CLASS_PERIODS_ARCHIVE: '/group_classes_periods_archives',
  /* PaymentConditionTypes */
  PAYMENT_CONDITION_TYPES: '/payment_condition_types',
  /* PaymentCreditCard */
  PAYMENT_CREDIT_CARD: '/payment_credit_cards',
  /* Token */
  LOGIN: '/login',
  URL_SSO: '/url_sso',
  URL_SSO_LOGOUT: '/url_logout_sso',
  LOGIN_SSO: '/login_sso',
  /* User */
  ME: '/users/me',
  /* SielBleuUser */
  SIEL_BLEU_USERS: '/siel_bleu_users',
  SIEL_BLEU_USERS_RESET_KEY_REQUEST: '/siel_bleu_users/reset_key_request',
  SIEL_BLEU_USERS_PASSWORD_UPDATE: '/siel_bleu_users/password_update',
  /* Person */
  PEOPLE: '/people',
  /* Message */
  EMAIL: '/send_email',
  SMS: '/send_sms',
  /* Payment: Cheques */
  PAYMENT_CHEQUES: '/payment_cheques',
  /* Bank deposits */
  BANK_DEPOSITS: '/bank_deposits',
  /* Payment transfer */
  PAYMENT_TRANSFER: '/payments/:id/transfer',
  /* Payment ventilated */
  PAYMENT_VENTILATED: '/payments/export',
  MEDIA_OBJECTS: '/media_objects',
  SHARED_SESSION: '/multiple_session_shareds',
  SHARED_SESSIONS_FOR_BENEFICIARY: '/beneficiaries/:id/session_shareds',
  SHARED_SESSIONS_DOWNLOAD: '/session_shareds/:id/download',
};
