// @flow
import IconBars from '@icons/components/IconBars';
import { useTranslation } from 'react-i18next';
import IconGroup from '@icons/components/IconGroup';
import IconCamera from '@icons/components/IconCamera';
import clsx from 'clsx';
import { GROUP_CLASSES_FILTERS } from '@groupClass/constants';
import type { GroupClassesFilterType } from '@groupClass/types';

const GroupClassesFilter = (
  { groupClassesFilter,
    onChangeGroupClassesFilter,
  }: { groupClassesFilter: GroupClassesFilterType,
      onChangeGroupClassesFilter: (filter: GroupClassesFilterType) => void,
    }) => {
  const { t } = useTranslation();

  return (
    <div className="flex mb-5">
      <div
        className={ clsx('filter', { 'filter-selected': groupClassesFilter === GROUP_CLASSES_FILTERS.ALL }) }
        onClick={ () => onChangeGroupClassesFilter(GROUP_CLASSES_FILTERS.ALL) }
      >
        <span className="icon-text">
          <span className="icon">
            <IconBars />
          </span>
          <span>{ t('groupClasses.tag.all') }</span>
        </span>
      </div>
      <div
        className={ clsx('filter', { 'filter-selected': groupClassesFilter === GROUP_CLASSES_FILTERS.IN_PERSON }) }
        onClick={ () => onChangeGroupClassesFilter(GROUP_CLASSES_FILTERS.IN_PERSON) }
      >
        <span className="icon-text">
          <span className="icon">
            <IconGroup />
          </span>
          <span>{ t('groupClasses.tag.inPerson') }</span>
        </span>
      </div>
      <div
        className={ clsx('filter', { 'filter-selected': groupClassesFilter === GROUP_CLASSES_FILTERS.ONLINE }) }
        onClick={ () => onChangeGroupClassesFilter(GROUP_CLASSES_FILTERS.ONLINE) }
      >
        <span className="icon-text">
          <span className="icon">
            <IconCamera />
          </span>
          <span>{ t('groupClasses.tag.online') }</span>
        </span>
      </div>
    </div>
  );
};

export default GroupClassesFilter;
