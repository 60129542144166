// @flow
import type { Error, Listener } from '@core/types';
import type { Participant } from '@participant/types';
import * as coreConstants from '@app/constants/paths';
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import { parseParticipantFromPayload, parseParticipant } from '@participant/helpers/ParticipantsUtils';

export type ParticipantServiceData = {
  participant: Participant | null,
  isLoading: boolean,
  errors: Error[],
};

type Clear = () => void;
type CleanErrors = () => void;
type FetchOne = (id: number, language: string) => Promise<Participant>;
type Create = (data: Participant) => Promise<Participant | any>;
type Update = (id: number, data: Participant) => Promise<any>;
type Remove = (id: number) => Promise<boolean>;
type OnChange = (listener: Listener) => Function;
type Trigger = () => void;
type UpdateValues = (newParticipant: Participant | null, newIsLoading: boolean, newErrors?: Error[]) => void;

const { API_PATHS } = coreConstants;
const { PARTICIPANTS } = API_PATHS;

let sourceOne = ApiService.createToken();

class ParticipantService {
  constructor() {
    this.eventEmitter = new EventEmitter();

    this.participant = null;
    this.isLoading = false;
    this.errors = [];
  }

  eventEmitter: EventEmitter;
  participant: Participant | null;
  isLoading: boolean;
  errors: Error[];

  get participantValue(): Participant | null {
    return this.participant;
  }

  get isLoadingValue(): boolean {
    return this.isLoading;
  }

  get errorsValues(): Error[] {
    return this.errors;
  }

  updateValues: UpdateValues = (newParticipant: Participant | null, newIsLoading: boolean, newErrors: Error[] = []): void => {
    this.participant = newParticipant;
    this.isLoading = newIsLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  clear: Clear = (): void => {
    this.updateValues(null, false, []);
  };

  cleanErrors: CleanErrors = (): void => {
    this.updateValues(this.participant, false, []);
  };

  fetchOne: FetchOne = (id: number, language: string): Promise<Participant> => {
    sourceOne.cancel();
    sourceOne = ApiService.createToken();

    this.updateValues(this.participant, true, this.errors);

    return ApiService.request({
      method: 'get',
      url: `${ PARTICIPANTS }/${ id }`,
      cancelToken: sourceOne.token,
      headers: {
        'Accept-Language': language,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const participantFromApi = parseParticipantFromPayload(data);

          this.updateValues(participantFromApi, false, this.errors);
          return participantFromApi;
        }
        return Promise.reject();
      })
      .catch((error) => {
        const errorsViolations = error.response?.data?.violations || error;

        this.updateValues(this.participant, false, [...this.errors, { error: errorsViolations }]);
        return Promise.reject();
      });
  };

  create: Create = (data: Participant): Promise<Participant | any> => {
    this.updateValues(this.participant, true, []);

    return ApiService.request({
      method: 'post',
      url: PARTICIPANTS,
      data: parseParticipant(data),
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newParticipant: Participant = parseParticipantFromPayload(data);

          this.updateValues(newParticipant, false);
          return newParticipant;
        }
        this.updateValues(this.participant, false, this.errors);
        return Promise.reject();
      })
      .catch((error) => {
        const errorsViolations = error.response?.data?.violations || this.errors;
        this.updateValues(this.participant, false, errorsViolations);
        return Promise.reject();
      });
  };

  update: Update = (id: number, data: Participant): Promise<any> => {
    this.updateValues(this.participant, true, []);

    return ApiService.request({
      method: 'put',
      url: `${ PARTICIPANTS }/${ id }`,
      data: parseParticipant(data),
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newParticipant: Participant = parseParticipantFromPayload(data);
          this.updateValues(newParticipant, false);
          return Promise.resolve();
        }
        this.updateValues(this.participant, false, this.errors);
        return Promise.reject();
      })
      .catch((error) => {
        const errorsViolations = error.response?.data?.violations || this.errors;
        this.updateValues(this.participant, false, errorsViolations);
        return Promise.reject();
      });
  };

  remove: Remove = (id: number): Promise<boolean> => {
    this.updateValues(this.participant, true, []);

    return ApiService.request({
      method: 'delete',
      url: `${ PARTICIPANTS }/${ id }`,
    })
      .then((payload) => {
        const { status } = payload;
        this.updateValues(this.participant, false);
        if (status === 204) {
          this.updateValues(null, false, []);
          return true;
        }
        return false;
      })
      .catch(() => {
        this.updateValues(this.participant, false, this.errors);
        return Promise.reject(false);
      });
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = () => {
    this.eventEmitter.trigger({
      participant: this.participant,
      isLoading: this.isLoading,
      errors: this.errors,
    });
  };
}

const instance: ParticipantService = new ParticipantService();
export default instance;
