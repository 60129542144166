// @flow
import moment from 'moment/dist/moment';

import type { Coach, ShortCoach } from '@coach/types';
import type {
  GroupClass,
  GroupClassPayload,
  GroupClassOutput,
  GroupClassDetailed,
  GroupClassDetailedPayload,
  Period,
  PeriodPayload,
  PeriodOutput,
  ShortGroupClass,
  ShortGroupClassPayload,
  GroupClassRegistered,
  GroupClassRegisteredPayload,
} from '@groupClass/types';
import { parseActivity } from '@activity/services/ActivitiesUtils';
import type { NavigationTab } from '@shared/Navigation/NavigationPills/types';

import * as groupClassConstants from '@groupClass/constants';

export const parseGroupClassFromPayload = (groupClass: GroupClassPayload): GroupClass => ({
  id: groupClass.id,
  activity: parseActivity(groupClass.activity),
  address: {
    id: groupClass.address.id,
    number: groupClass.address.number ?? null,
    complementNumber: groupClass.address.complementNumber ?? null,
    streetName: groupClass.address.streetName,
    additionalLocalizationConstruction: groupClass?.address?.additionalLocalizationConstruction || '',
    distributionService: groupClass?.address?.distributionService || '',
    postalCode: groupClass.address.postalCode,
    city: groupClass.address.city,
    country: groupClass.address.country,
    latitude: groupClass?.address?.latitude ? parseFloat(groupClass.address.latitude) : null,
    longitude: groupClass?.address?.longitude ? parseFloat(groupClass.address.longitude) : null,
  },
  caseNumber: groupClass.caseNumber,
  coaches: groupClass.coaches,
  day: groupClass.day,
  description: groupClass.description,
  endDate: groupClass.endDate,
  endTime: new Date('1970-01-01T' + groupClass.endTime),
  groupClassKey: groupClass.groupClassKey,
  isValidated: groupClass.isValidated,
  name: groupClass.name,
  paymentStatus: groupClass.status,
  registrationContact: groupClass.registrationContact,
  registrationTerms: groupClass.registrationTerms,
  seasonClosedAt: groupClass.seasonClosedAt === null ? null : groupClass.seasonClosedAt,
  seasonKey: groupClass.seasonKey,
  sector: groupClass.sector,
  startDate: groupClass.startDate,
  startTime: new Date('1970-01-01T' + groupClass.startTime),
  type: groupClass.type,
  isOnline: groupClass.isOnline,
});

export const hydrateGroupClassDetailedFromPayload = (groupClass: GroupClassDetailedPayload): GroupClassDetailed => {
  const {
    id,
    activity,
    address,
    caseNumber,
    coaches,
    day,
    description,
    endDate,
    endTime,
    exceptDates,
    groupClassKey,
    isValidated,
    name,
    periods,
    registrationTerms,
    registrationContact,
    sector,
    seasonClosedAt,
    seasonKey,
    startDate,
    status,
    startTime,
    type,
    isOnline,
  } = groupClass;

  const groupClassNotDetailed: GroupClassPayload = {
    id,
    activity,
    address,
    caseNumber,
    coaches,
    day,
    description,
    endDate,
    endTime,
    groupClassKey,
    isValidated,
    name,
    registrationContact,
    registrationTerms,
    startDate,
    startTime,
    seasonKey,
    seasonClosedAt,
    sector,
    status,
    type,
    isOnline,
  };

  const groupClassNotDetailedHydrated = parseGroupClassFromPayload(groupClassNotDetailed);

  const periodsFormatted: Period[] = periods.map((period: PeriodPayload): Period => ({
    id: period.id,
    startDate: period.startDate,
    endDate: period.endDate,
    amount: period.amount,
    validatedAt: period.validatedAt === null ? null : period.validatedAt,
    hasPayment: period.hasPayment,
    archivedAt: period.archivedAt === null ? null : period.archivedAt,
  }));

  const coachesFormatted: Coach[] = coaches.map((coach: Coach): Coach => ({
    id: coach.id,
    sielBleuUser: {
      id: coach.sielBleuUser.id,
      email: coach.sielBleuUser.email,
      firstName: coach.sielBleuUser.firstName,
      lastName: coach.sielBleuUser.lastName,
      landlinePhone: coach.sielBleuUser.landlinePhone,
      mobilePhone: coach.sielBleuUser.mobilePhone,
    },
    contactInformation: coach.contactInformation,
    isMainCoach: coach.isMainCoach,
  }));

  return {
    ...groupClassNotDetailedHydrated,
    exceptDates,
    periods: periodsFormatted,
    coaches: coachesFormatted,
  };
};

export const parseGroupClassOut = (oldGroupClass: GroupClassDetailed): GroupClassOutput => {
  let newCoaches: ShortCoach[] = [];
  if (oldGroupClass.coaches && oldGroupClass.coaches.length > 0) {
    newCoaches = oldGroupClass.coaches.map((coach: Coach) => ({
      sielBleuUser: coach.sielBleuUser.id,
      contactInformation: coach.contactInformation,
      isMainCoach: coach.isMainCoach,
    }));
  }

  const periods: PeriodOutput[] = oldGroupClass.periods.map((period: Period) => {
    const amount = typeof period.amount === 'string' ? parseFloat(period.amount) : period.amount;

    return {
      id: period.id,
      startDate: moment(period.startDate).format('YYYY-MM-DD'),
      endDate: moment(period.endDate).format('YYYY-MM-DD'),
      amount: isNaN(amount) ? null : amount,
    };
  });

  return {
    id: oldGroupClass.id,
    coaches: newCoaches,
    description: oldGroupClass.description,
    endDate: moment(oldGroupClass.endDate).format('YYYY-MM-DD'),
    exceptDates: oldGroupClass.exceptDates,
    periods: periods,
    registrationContact: oldGroupClass.registrationContact,
    registrationTerms: oldGroupClass.registrationTerms,
    seasonClosedAt: oldGroupClass.seasonClosedAt ? moment(oldGroupClass.seasonClosedAt).format('YYYY-MM-DD') : null,
    startDate: moment(oldGroupClass.startDate).format('YYYY-MM-DD'),
    type: oldGroupClass.type,
  };
};

export const formatShortGroupClassFromPayload = (shortGroupClass: ShortGroupClassPayload): ShortGroupClass => ({
  id: shortGroupClass.id,
  name: shortGroupClass.name,
  seasonKey: shortGroupClass.seasonKey,
  address: {
    id: shortGroupClass.address.id,
    number: shortGroupClass.address.number,
    complementNumber: shortGroupClass.address.complementNumber,
    streetName: shortGroupClass.address.streetName,
    additionalLocalizationConstruction: shortGroupClass.address.additionalLocalizationConstruction || '',
    distributionService: shortGroupClass.address.distributionService || '',
    postalCode: shortGroupClass.address.postalCode,
    city: shortGroupClass.address.city,
    country: shortGroupClass.address.country,
    latitude: shortGroupClass?.address?.latitude ? parseFloat(shortGroupClass.address.latitude) : null,
    longitude: shortGroupClass?.address?.longitude ? parseFloat(shortGroupClass.address.longitude) : null,
  },
  coaches: shortGroupClass.coaches,
  startTime: new Date('1970-01-01T' + shortGroupClass.startTime),
  endTime: new Date('1970-01-01T' + shortGroupClass.endTime),
  day: shortGroupClass.day,
  activity: parseActivity(shortGroupClass.activity),
  seasonClosedAt: shortGroupClass.seasonClosedAt ? new Date(shortGroupClass.seasonClosedAt).toLocaleTimeString('fr-FR') : null,
});

export const formatGroupClassRegisteredFromPayload = (registered: GroupClassRegisteredPayload): GroupClassRegistered => ({
  registered: registered.registered,
  groupClass: formatShortGroupClassFromPayload(registered.groupClass),
  paymentStatus: registered.status,
  sortKey: registered.sortKey,
  unsubscribedAt: registered.unsubscribedAt,
});

export const getAdminGroupClassesTab = (): NavigationTab[] => (
  [groupClassConstants.TO_VALIDATE_TAB, groupClassConstants.GROUP_CLASSES_TAB, groupClassConstants.SEASON_CLOSED_TAB]
);
