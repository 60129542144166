// @flow

import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { PERSON_TYPE_PARTICIPANT } from '@person/constants';
import { WEB_PATHS } from '@app/constants/paths';
import ParticipantService from '@participant/services/ParticipantService';
import type { Participant } from '@participant/types';
import type { Error } from '@core/types';
import { validateParticipantForm } from '@participant/helpers/ParticipantsUtils';
import Toast from '@common/services/Toast/Toast';
import SielBleuUserRoute from '@shared/ProtectedRoute/components/SielBleuUserRoute';
import IconChevron from '@icons/components/IconChevron';
import Button, { buttonConstants } from '@shared/Button';
import PersonalForm from '@shared/Personal/components/PersonalForm';
import Modal from '@shared/Modal/Modal';
import toast from '@common/services/Toast/Toast';
const { PEOPLE } = WEB_PATHS;

const PeopleEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { peopleState } = location.state || {};
  const [ participant, setParticipant ] = useState<Participant | null>(ParticipantService.participantValue);
  const [ errors, setErrors ] = useState<Error[]>(ParticipantService.errorsValues);
  const [ isActiveModal, setIsActiveModal ] = useState<boolean>(false);
  const [ authorizeData, setAuthorizeData ] = useState<boolean>(true);

  const toggleAuthorizeData = useCallback((): void => {
    setAuthorizeData(!authorizeData);
  }, [authorizeData]);

  useEffect(() => {
    if (peopleState && peopleState?.type === PERSON_TYPE_PARTICIPANT) {
      setParticipant(peopleState);
    } else {
      // for now only participant can be updated
      navigate(PEOPLE, { state: { keepSearch: true } });
    }
  }, [peopleState]);

  const updateParticipant = ()=> {
    const formErrors = validateParticipantForm(participant);

    setErrors(formErrors);

    if (participant && formErrors.length === 0) {
      ParticipantService.update(participant.id, participant)
        .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellUpdated')))
        .then(() => navigate(PEOPLE, { state: { keepSearch: true } }));
    }
  };

  const footerModal: React$Node = (
    <footer className="modal-footer">
      <Button
        type={ buttonConstants.TERTIARY }
        onClick={ ()=> setIsActiveModal(false) }
        isOutlined
      >
        { t('common.cancel') }
      </Button>

      <Button
        type={ buttonConstants.DANGER }
        className="button is-success confirm"
        onClick={ () => deleteParticipant() }
      >
        { t('groupClasses.groupClass.peoples.form.popUpConfirm') }
      </Button>
    </footer>
  );

  const deleteParticipant = ()=> {
    if (participant) {
      ParticipantService.remove(participant.id)
        .then(() => {
          toast.success(t('people.delete.success'));
          setIsActiveModal(false);
          navigate(PEOPLE);
        })
        .catch((error) => {
          toast.error(error || t('people.delete.error'));
          setIsActiveModal(false);
        });
    }
  };

  return (
    <SielBleuUserRoute>
      <div className="page">
        <div className="container">
          <div className="people-cta mt-5">
            <Link
              to={ PEOPLE }
              state={ { keepSearch: true } }
              className="button button-tertiary button-position mb-5"
            >
              <IconChevron className="back-icon"/>
              { t('groupClasses.groupClass.peoples.form.cancelAddToGroupClass') }
            </Link>
          </div>
          <div className="detail-people">

            <div className="name-people is-flex is-justify-content-space-between">
              <h1>
                { `${ participant?.firstName } ${ participant?.lastName.toUpperCase() }` }
              </h1>
              <div>
                <Button
                  onClick={ updateParticipant }
                  type={ buttonConstants.PRIMARY }
                >
                  { t('groupClasses.groupClass.peoples.form.confirmButton') }
                </Button>
                <Button
                  className="ml-3"
                  onClick={ () => setIsActiveModal(true) }
                  type={ buttonConstants.DANGER }
                >
                  { t('people.delete') }
                </Button>
              </div>

            </div>
            <div className="detail-people-container">
              <div className="columns">
                <div className="column is-12">
                  <div className="content">
                    <PersonalForm
                      data={ null }
                      user={ participant }
                      errors={ errors }
                      groupClass={ null }
                      authorizeData={ authorizeData }
                      setUser={ setParticipant }
                      toggleAuthorizeData={ toggleAuthorizeData }
                    />
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isActive={ isActiveModal }
              onClose={ ()=> setIsActiveModal(false) }
              content={ 'test' }
              footer={ footerModal }>
              { t('people.delete.confirm') }
            </Modal>
          </div>
        </div>
      </div>
    </SielBleuUserRoute>
  );
};

export default PeopleEdit;
