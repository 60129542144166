// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { getLatLngByPlaceId } from '@shared/Locations/services/GeocodingService';
import { useMobileOrientation } from 'react-device-detect';

import clsx from 'clsx';

import type { User } from '@user/types';
import type { AdditionalData, Option } from '@core/types';
import type { Coordinates, SearchCity, LocationGroupClass } from '@shared/Locations/types';
import type { GroupClass, GroupClassesFilterType } from '@groupClass/types';

import RolesHelpers from '@user/helpers/RolesHelpers';

import Pagination from '@shared/Pagination';
import TitleBanner from '@banner/components/TitleBanner';
import SearchBanner from '@search/components/SearchBanner';
import SearchVisitor from '@search/components/SearchVisitor';
import CardItem from '@groupClass/components/CardItem';
import Map from '_common/components/map';
import ToggleButton from '_common/components/toggle-button/ToggleButton';
import PublicRoute from '@shared/PublicRoute/components/PublicRoute';
import Loader from '_common/components/loader/Loader';
import NavigationPills from '@shared/Navigation/NavigationPills';

import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import GroupClassesService, { type GroupClassesServiceData } from '@groupClass/services/GroupClassesService';

import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { getLanguageFormat } from '_common/services/LanguageUtils';
import { WEB_PATHS } from '@app/constants/paths';
import { DEFAULT_ITEMS_PER_PAGE } from '@api/constants';
import {
  SEASON_CLOSED_KEY,
  GROUP_CLASSES_KEY,
  TO_VALIDATE_KEY,
  ADMIN_NAVIGATION_TABS, GROUP_CLASSES_FILTERS,
} from '@groupClass/constants';

const {
  GROUP_CLASS,
  GROUP_CLASSES,
  GROUP_CLASSES_TO_VALIDATE,
  GROUP_CLASSES_SEASON_CLOSED,
} = WEB_PATHS;

const DEFAULT_PAGE = 1;
const DEFAULT_ZOOM_LEVEL = 5;
const DEFAULT_COORDINATES = { longitude: 0, latitude: 0 };

const GroupClasses = (): React$Node => {
  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);

  // Memoized values
  const isCoach = useMemo((): boolean => (
    RolesHelpers.isCoach(currentUser)
  ), [currentUser]);

  const isManager = useMemo((): boolean => (
    RolesHelpers.isManager(currentUser)
  ), [currentUser]);

  const isAdmin = useMemo((): boolean => (
    RolesHelpers.isAdmin(currentUser)
  ), [currentUser]);

  const isBeneficiary = useMemo((): boolean => (
    RolesHelpers.isBeneficiary(currentUser)
  ), [currentUser]);

  const { isLandscape } = useMobileOrientation();
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [ myClassesOnly, setMyClassesOnly ] = useState<boolean>(isCoach);
  const [ mySectorGroupClasses, setMySectorGroupClasses ] = useState<boolean>(isManager);
  const [ page, setPage ] = useState<number>(DEFAULT_PAGE);
  const [ lastPage, setLastPage] = useState<number>(page);
  const [ search, setSearch ] = useState<SearchCity | null>(GroupClassesService?.filters?.search || null);
  const [ day, setDay ] = useState<Option | null>(GroupClassesService?.filters?.day || null);
  const [ activity, setActivity ] = useState<Option | null>(GroupClassesService?.filters?.activity || null);
  const [ coordinates, setCoordinates ] = useState<Coordinates>(DEFAULT_COORDINATES);
  const [ groupClassKey, setGroupClassKey ] = useState<string>('');
  const [ caseNumber, setCaseNumber ] = useState<string>('');
  const [ groupClassesFilter, setGroupClassesFilter ] = useState<GroupClassesFilterType>(GROUP_CLASSES_FILTERS.ALL);

  const [ seeMap, setSeeMap ] = useState<boolean>(isLandscape);
  const [ userInteracted, setUserInteracted ] = useState<boolean>(false);
  const [ mapZoom, setMapZoom ] = useState<number>(DEFAULT_ZOOM_LEVEL);
  const [ zoomOnCountry, setZoomOnCountry ] = useState<boolean>(true);

  // States used to display the list of Group Classes, next to the map and their markers on the map
  const [ additionalData, setAdditionalData ] = useState<AdditionalData>(GroupClassesService.additionalData);
  const [ coordinatesAPI, setCoordinatesAPI ] = useState<Array<LocationGroupClass> | null>(GroupClassesService.coordinatesAPI); // coordinates of the markers (can be filtered)
  const [, setFilters ] = useState<Object>(GroupClassesService.filters); // filters = result of the search (filters.label = zipcode and city name, filters.value = lat and lng)
  const [ groupClasses, setGroupClasses ] = useState<GroupClass[] | null>(GroupClassesService.groupClasses);
  const [ isCoordinatesLoading, setIsCoordinatesLoading ] = useState<boolean>(GroupClassesService.isCoordinatesLoading);
  const [ isGroupClassLoading, setIsGroupClassLoading ] = useState<boolean>(GroupClassesService.isGroupClassLoading);

  const groupClassesCardItemCondition = seeMap && (isBeneficiary || !currentUser) && !isCoordinatesLoading;

  const [paramsGroupClasses, setParamsGroupClasses] = useState(() => ({
    activity,
    caseNumber,
    currentPage: page,
    day,
    groupClassKey,
    orderActivityName: 'ASC',
    myClassesOnly,
    mySectorGroupClasses,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    isSeasonClosed: pathname === GROUP_CLASSES_SEASON_CLOSED,
    isValidated: pathname !== GROUP_CLASSES_TO_VALIDATE,
    isOnline: groupClassesFilter,
  }));

  const paramsCoordinates = useMemo(() => ({
    pagination: false,
    coordinates,
  }), [coordinates]);

  const cleanCoordinatesAPI = useMemo((): Array<LocationGroupClass> => (
    coordinatesAPI?.filter((coordinate) => coordinate.lng !== null && coordinate.lat !== null) || []
  ), [coordinatesAPI]);

  const groupClassesBlockClassName = useMemo((): string => clsx({
    'group-classes-block': true,
    'group-classes-no-block': additionalData.totalItems === 0,
  }), [additionalData.totalItems]);

  const groupClassesColumnMapItemsClassName = useMemo((): string => clsx({
    'column': true,
    'is-6-desktop col-map-items': groupClassesCardItemCondition,
    'is-12 col-map-items': !(groupClassesCardItemCondition),
  }), [groupClassesCardItemCondition]);

  const groupClassesCardItemClassName = useMemo((): string => clsx({
    'column': true,
    'is-6-widescreen is-6-desktop is-half-tablet is-12-mobile': groupClassesCardItemCondition,
    'is-3-widescreen is-4-desktop is-half-tablet is-12-mobile': !groupClassesCardItemCondition,
  }), [groupClassesCardItemCondition]);

  const totalItems = useMemo((): string => (
    additionalData.totalItems.toString() || '0'
  ), [additionalData]);

  const isSelectedKey = useMemo((): string => {
    if (pathname === GROUP_CLASSES_SEASON_CLOSED) return SEASON_CLOSED_KEY;
    if (pathname === GROUP_CLASSES_TO_VALIDATE) return TO_VALIDATE_KEY;
    if (pathname === GROUP_CLASSES) return GROUP_CLASSES_KEY;
    return '';
  }, [pathname]);

  const displayValidationMessage = useMemo((): boolean => (
    GROUP_CLASSES_TO_VALIDATE === pathname
  ), [pathname]);

  // Callbacks
  const fetchGroupClasses = useCallback((): void => {
    GroupClassesService.fetchGroupClassesAndCoordinates(language, { ...paramsGroupClasses, ...paramsCoordinates });
  }, [language, paramsGroupClasses, paramsCoordinates]);

  const handleSearch = useCallback((newSearch: null | SearchCity): void => {
    if (newSearch?.value) {
      if (newSearch.value.place_id) {
        const placeId: string = newSearch.value.place_id;
        GroupClassesService.setFilters({ search: newSearch });
        getLatLngByPlaceId(placeId)
          .then(({ lat, lng }) => {
            setCoordinates({ latitude: lat, longitude: lng });
            setSearch(newSearch);
          });
      }
      if (newSearch.value.lat && newSearch.value.lng) {
        const latitude: number = newSearch.value.lat;
        const longitude: number = newSearch.value.lng;

        GroupClassesService.setFilters({ search: newSearch });
        setCoordinates({ latitude, longitude });
        setSearch(newSearch);
      }
    } else {
      setSearch(null);
      setCoordinates(DEFAULT_COORDINATES);
      GroupClassesService.setFilters({ ...GroupClassesService.filters, search: null });
    }
  }, []);

  /**
   * Set the list of displayed group classes.
   * They serve as 'markers' on the Map component.
   */
  const handleUpdateGroupClasses = (data: GroupClassesServiceData): void => {
    setGroupClasses(data.groupClasses);
    setFilters(data.filters);
    setAdditionalData(data.additionalData);
    setCoordinatesAPI(data.coordinatesAPI);
    setIsGroupClassLoading(data.isGroupClassLoading);
    setIsCoordinatesLoading(data.isCoordinatesLoading);
  };

  const handleUserDataUpdate = (data: AuthServiceData): void => {
    setCurrentUser(data.user);
  };

  const reloadGroupClasses = useCallback((event: SyntheticEvent<EventTarget>): void => {
    event.preventDefault();
    fetchGroupClasses();
  }, [fetchGroupClasses]);

  const handleSeeMap = useCallback((toggle: boolean): void => {
    setSeeMap(toggle);
    setUserInteracted(true);
  }, [setSeeMap, setUserInteracted]);

  const handleDay = useCallback((day: Option): void => {
    setDay(day);
    GroupClassesService.setFilters({ day });
  }, []);

  const handleActivity = useCallback((activity: Option): void => {
    setActivity(activity);
    GroupClassesService.setFilters({ activity });
  }, []);

  const handleChangeGroupClassFilter = useCallback((filter): void => {
    setGroupClassesFilter(filter);
    setSeeMap(filter !== GROUP_CLASSES_FILTERS.ONLINE);
    GroupClassesService.setFilters({ isOnline: filter });
    setParamsGroupClasses({ ...paramsGroupClasses, isOnline: filter });
  }, []);

  const handleChangeAddress = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    handleSearch(event);
  }, [handleSearch]);

  const handleChangeCaseNumber = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setCaseNumber(event.target.value);
  }, []);

  const handleChangeGroupClassKey = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setGroupClassKey(event.target.value);
  }, []);

  // On mount
  useEffect(() => AuthService.onChange(handleUserDataUpdate), []);

  useEffect(() => GroupClassesService.onChange(handleUpdateGroupClasses), []);

  useEffect(() => {
    if (search && search.value?.place_id) {
      handleSearch(search);
      if (page !== lastPage) {
        setLastPage(page);
        fetchGroupClasses();
      }
    } else {
      fetchGroupClasses();
    }
  }, [page, mySectorGroupClasses]);

  // On update
  useEffect(() => {
    if (isManager) {
      setMySectorGroupClasses(true);
    }
  }, [isManager]);

  useEffect(() => {
    if (additionalData.totalItems === 0) {
      setMapZoom(DEFAULT_ZOOM_LEVEL);
    }
  }, [additionalData]);

  useEffect(() => {
    if (!userInteracted) {
      setSeeMap(isLandscape);
    }
  }, [userInteracted, isLandscape, setSeeMap]);

  useEffect(() => {
    if (search === null || groupClasses.length === 0) {
      setZoomOnCountry(true);
    }
  }, [groupClasses]);

  useEffect(() => {
    fetchGroupClasses();
  }, [paramsGroupClasses]);

  return (
    <PublicRoute preventPortraitMode={ isAdmin || isCoach || isManager }>
      <section className="group-classes-container container">
        <div className="columns is-flex-wrap-wrap">
          { (isBeneficiary || !currentUser) ? (
            <div className="column is-12">
              <SearchVisitor
                activity={ activity }
                day={ day }
                locale={ currentUser ? getLanguageFormat(currentUser.language) : getLanguageFormat(language) }
                value={ search }
                groupClassesFilter={ groupClassesFilter }
                onChangeActivity={ handleActivity }
                onChangeAddress={ handleChangeAddress }
                onChangeDay={ handleDay }
                onChangeGroupClassesFilter={ handleChangeGroupClassFilter }
                onSubmit={ reloadGroupClasses }
              />
            </div>
          )
            : (
              <div className="column is-12">
                <TitleBanner>
                  <NavigationPills navigationTabs={ ADMIN_NAVIGATION_TABS } isSelectedKey={ isSelectedKey } />
                </TitleBanner>
                <SearchBanner
                  isAdmin={ isAdmin }
                  isManager={ isManager }
                  myClassesOnly={ myClassesOnly }
                  mySectorGroupClasses={ mySectorGroupClasses }
                  activity={ activity }
                  caseNumber={ caseNumber }
                  day={ day }
                  groupClassKey={ groupClassKey }
                  value={ search }
                  locale={ currentUser ? getLanguageFormat(currentUser.language) : getLanguageFormat(language) }
                  onSearch={ handleSearch }
                  onChangeAddress={ handleChangeAddress }
                  onChangeCaseNumber={ handleChangeCaseNumber }
                  onChangeGroupClassKey={ handleChangeGroupClassKey }
                  onChangeDay={ handleDay }
                  setMyClassesOnly={ setMyClassesOnly }
                  setMySectorGroupClasses={ setMySectorGroupClasses }
                  onChangeActivity={ handleActivity }
                  onChangeGroupClassesFilter={ handleChangeGroupClassFilter }
                  groupClassesFilter={ groupClassesFilter }
                  onSubmit={ reloadGroupClasses }
                />
                { displayValidationMessage && (
                  <p className="group-classes-validation-message">
                    { t('groupClasses.toValidateSentence') }
                  </p>
                ) }
              </div>
            ) }
          { isGroupClassLoading && !isBeneficiary && (
            <Loader loaderType={ LOADER_TYPE_PAGE } />
          ) }
          { additionalData && !isGroupClassLoading && (
            <>
              { (isBeneficiary || !currentUser) && (
                <div className="column is-12">
                  { groupClassesFilter === GROUP_CLASSES_FILTERS.IN_PERSON && (
                    <p className="mb-4">
                      <Trans
                        i18nKey="groupClasses.inPerson.hint"
                        components={ { click: <span className="online-hint" onClick={ () => handleChangeGroupClassFilter(GROUP_CLASSES_FILTERS.ONLINE) } /> } }
                      />
                    </p>
                  ) }
                  <div className="group-classes-results">
                    <span className="search-results">
                      { t('groupClasses.results', { totalItems }) }
                    </span>
                    { groupClassesFilter !== GROUP_CLASSES_FILTERS.ONLINE && (
                      <ToggleButton
                        name="see-map"
                        onChange={ handleSeeMap }
                        className="toggle"
                        defaultChecked={ seeMap }
                      >
                        { t('groupClasses.seeMap') }
                      </ToggleButton>
                    ) }
                  </div>
                </div>
              ) }
              <div className="column is-12 col-map-items">

                <div className={ groupClassesBlockClassName }>
                  <div className="columns columns-block-classname">
                    { seeMap && (isBeneficiary || !currentUser) && !isCoordinatesLoading && (
                      <div className="column is-6-desktop is-12-tablet">
                        <Map
                          markers={ cleanCoordinatesAPI }
                          handleZoomChange={ setMapZoom }
                          zoom={ mapZoom }
                          isDataLoading={ isCoordinatesLoading }
                          zoomOnCountry={ zoomOnCountry }
                          setZoomOnCountry={ setZoomOnCountry }
                        />
                      </div>
                    ) }
                    { additionalData.totalItems > 0 && (
                      <div className={ groupClassesColumnMapItemsClassName }>
                        <div className="group-classes">
                          <div className="columns is-flex-wrap-wrap is-mobile">
                            { groupClasses?.map((groupClass: GroupClass) => (
                              <div
                                className={ groupClassesCardItemClassName }
                                key={ groupClass.id }
                              >
                                <CardItem
                                  groupClass={ groupClass }
                                  paymentStatus={ groupClass.paymentStatus }
                                  onClick={ () => navigate(GROUP_CLASS.replace(':classId', String(groupClass.id))) }
                                />
                              </div>
                            )) }
                          </div>
                        </div>
                      </div>
                    ) }
                  </div>
                  { additionalData.totalItems === 0 && (
                    <div className="group-classes-no-group">
                      <div className="column is-12">
                        <p>{ t('groupClasses.noResult') }</p>
                      </div>
                    </div>
                  ) }
                </div>

              </div>
              { additionalData.lastPage > 1 && (
                <div className="column is-12">
                  <div className="group-classes-pagination">
                    <Pagination
                      changePage={ setPage }
                      currentPage={ additionalData.currentPage }
                      lastPage={ additionalData.lastPage }
                      alignment="is-centered"
                      isRounded
                    />
                  </div>
                </div>
              ) }
            </>
          ) }
        </div>
      </section>
    </PublicRoute>
  );
};

export default GroupClasses;
