// @flow
import type { ActionMeta } from 'react-select';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import AdvancedSelectField from '@shared/AdvancedSelectField/components/AdvancedSelectField';
import Toast from '_common/services/Toast/Toast';
import InputField from '_common/components/input-field/InputField';
import Button, { buttonConstants } from '@shared/Button';
import TextareaField from '_common/components/textarea-field/TextareaField';
import Coaches from '@coach/components/Coaches';
import Periods from '@period/components/Periods';
import Registration from '@groupClass/components/Admin/Edit/Registration';
import Time from '@groupClass/components/Admin/Edit/Time';
import SeasonDates from '@groupClass/components/Admin/Edit/SeasonDates';

import ObjectHelpers from '@helpers/ObjectHelpers';
import GroupClassService from '@groupClass/services/GroupClassService';
import TranslationHelpers from '@helpers/TranslationHelpers';
import useSielBleuUsers from '@user/hooks/useSielBleuUsers';
import { scrollToFirstErrorElement } from '_common/services/CommonUtils';
import { formatAddressToString } from '@shared/Locations/services/AddressUtils';
import DateHelpers from '@helpers/DateHelpers';

import type { Error, Option, ErrorProps } from '@core/types';
import type { GroupClassDetailed } from '@groupClass/types';

import { errorColor } from '@app/constants/styles';
import * as groupClassesConstants from '@app/constants/constants';
import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { ADMIN_HEADER_INFORMATION } from '@layout/constants';
import { ERROR_IS_BLANK, INVALID_DURATION } from '@helpers/TranslationHelpers';
import {
  RE_MATCH_PERIOD_AMOUNT_PATTERN,
  RE_MATCH_PERIOD_STARTDATE_PATTERN,
  RE_MATCH_PERIOD_ENDDATE_PATTERN,
  RE_MATCH_COACHES_PATTERN,
} from '@helpers/Regex';
import { WEB_PATHS } from '@app/constants/paths';

const { GROUP_CLASS, PAGE_NOT_FOUND } = WEB_PATHS;

const {
  DESCRIPTION_LENGTH_LIMIT,
  GROUP_CLASS_TYPES,
  GROUP_CLASS_TYPE_SIEL_BLEU,
  GROUP_CLASS_TYPE_BENEFICIARY,
  GROUP_CLASS_TYPE_INTERMEDIATE_WITH_COST,
  GROUP_CLASS_TYPE_SUBSIDIZED_WITH_COST,
} = groupClassesConstants;

type Props = {
  groupClass: GroupClassDetailed,
};

export const GroupClassDetailedEdit = (props: Props): React$Node => {
  const { groupClass: initialGroupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { classId } = useParams();

  const [ groupClass, setGroupClass ] = useState<GroupClassDetailed>(initialGroupClass);
  const [ errors, setErrors ] = useState<Error[]>([]);
  const [ isAlreadyEdited, setIsAlreadyEdited ] = useState<boolean>(false);

  const [ users, isUsersLoading ] = useSielBleuUsers(language);

  useEffect(() => {
    GroupClassService.fetchGroupClassById(classId, language)
      .then(setGroupClass)
      .catch(() => navigate(PAGE_NOT_FOUND));
  }, [classId, language]);

  useEffect(() => {
    if (!isUsersLoading && errors.length > 0) {
      let errorsCopy = ObjectHelpers.deepClone(errors);
      errorsCopy.forEach((error) => {
        if (error.propertyPath === 'address.postalCode')
          error.propertyPath = 'address';
      });
      scrollToFirstErrorElement(document.getElementsByClassName('input-edit-form'), errorsCopy);
    }
  }, [errors, isUsersLoading]);

  const classTypeOption = useMemo((): Option | null => (
    groupClass?.type
      ? {
        label: t(`groupClasses.groupClass.type.${ groupClass.type }`),
        value: groupClass.type,
      }
      : null
  ), [groupClass, language]);

  const dayValue = useMemo((): string => (
    groupClass ? DateHelpers.getDayOfWeek(groupClass.day, language) : ''
  ), [groupClass, language]);

  const activityValue = useMemo((): string => (
    groupClass?.activity?.name || ''
  ), [groupClass]);

  const handleChange = useCallback((event: SyntheticEvent<HTMLInputElement>): void => {
    const newGroupClass = ObjectHelpers.deepClone(groupClass);
    const { name, value } = event.currentTarget;

    newGroupClass[name] = (name === 'startDate' || name === 'endDate') ? new Date(value) : value;

    const newErrors: Error[] = ObjectHelpers.deepClone(errors)
      .filter((error) => error.propertyPath !== name);
    setErrors(newErrors);

    if (name === 'registrationTerms') {
      setIsAlreadyEdited(true);
    }

    setGroupClass(newGroupClass);
  }, [groupClass, errors, setErrors]);

  const handleChangeSelect = useCallback((newValue: Option, actionMeta: ActionMeta<Option>): void => {
    const newGroupClass = ObjectHelpers.deepClone(groupClass);
    const { action, name } = actionMeta;

    newGroupClass[name] = action === 'clear' ? '' : newValue.value;

    setGroupClass(newGroupClass);
  }, [groupClass]);

  const classTypeOptions = useMemo((): Option[] => (
    GROUP_CLASS_TYPES.map((classType) => ({
      label: t(`groupClasses.groupClass.type.${ classType }`),
      value: classType,
    }))
  ), [language]);

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    errors.forEach((error) => {
      let propertyPath = error.propertyPath;
      if (propertyPath === 'address.postalCode') propertyPath = 'address';
      Object.assign(errorsPropsTmp, {
        [`${ propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          labelTextAreaProps: {
            className: 'has-text-danger edit-label',
          },
          selectFieldHelperText: { text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)), textColor: 'is-danger' },
          error: {
            text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)),
            textColor: 'is-danger',
          },
        },
      });
    });
    return errorsPropsTmp;
  }, [errors, language]);

  const editGooglePlacesClassName = useMemo((): string => clsx({
    'edit-google-address-autocomplete': true,
    'edit-no-margin-bottom': !!errorsProps['address']?.error,
    'input-edit-form': true,
  }), [errorsProps['address']?.error]);

  const editTextareaFieldClassName = useMemo((): string => clsx({
    'edit-description': true,
    'field-border-error': !!errorsProps.description?.color,
    'input-edit-form description': true,
  }), [errorsProps.description?.color]);

  const handleCancel = useCallback((): void => {
    if (groupClass) {
      navigate(GROUP_CLASS.replace(':classId', String(groupClass.id)));
    }
  }, [groupClass]);

  const cleanError = useCallback((customErrors: Error[]): Error[] => {
    let newErrors = [...customErrors];
    if (groupClass) {
      if (newErrors.findIndex((err) => err.propertyPath === 'noPeriods') !== -1
        && (groupClass.type !== GROUP_CLASS_TYPE_SIEL_BLEU
          && groupClass.type !== GROUP_CLASS_TYPE_BENEFICIARY
          && groupClass.type !== GROUP_CLASS_TYPE_SUBSIDIZED_WITH_COST
          && groupClass.type !== GROUP_CLASS_TYPE_INTERMEDIATE_WITH_COST)) {
        newErrors = newErrors.filter((err) => err.propertyPath !== 'noPeriods');
        groupClass.periods.forEach((period, index) => {
          let amount = String(period.amount);
          if (amount === '') {
            newErrors = [...newErrors, { propertyPath: `periods[${ index }].amount`, code: ERROR_IS_BLANK, message: '' }];
          }
        });
      }

      if (newErrors.findIndex((err) => err.propertyPath === 'noPeriods') !== -1
        && (groupClass.type === GROUP_CLASS_TYPE_SIEL_BLEU
        || groupClass.type === GROUP_CLASS_TYPE_BENEFICIARY
        || groupClass.type === GROUP_CLASS_TYPE_SUBSIDIZED_WITH_COST
        || groupClass.type === GROUP_CLASS_TYPE_INTERMEDIATE_WITH_COST)
        && groupClass.periods.length > 0) {
        newErrors = newErrors.filter((err) => err.propertyPath !== 'noPeriods');
      }

      newErrors.forEach((err: Error) => {
        if (err.propertyPath.match(RE_MATCH_COACHES_PATTERN)) {
          newErrors = newErrors.filter((err) => !err.propertyPath.match(RE_MATCH_COACHES_PATTERN));
          groupClass.coaches.map((coach, index) => {
            if (!coach.id) {
              const error = { propertyPath: `coaches[${ index }].name`, code: ERROR_IS_BLANK, message: '' };
              newErrors = [...newErrors, error];
            }
          });
        }
        if (err.propertyPath.match(RE_MATCH_PERIOD_AMOUNT_PATTERN)) {
          groupClass.periods.forEach((period, index) => {
            let amount = String(period.amount);
            if (amount === '') {
              newErrors = [...newErrors, { propertyPath: `periods[${ index }].amount`, code: ERROR_IS_BLANK, message: '' }];
            }
            if (amount !== '') {
              newErrors = newErrors.filter((err) => err.propertyPath !== `periods[${ index }].amount`);
            }
          });
          const periodNumber = parseInt(err.propertyPath.split('[')[1].split(']')[0], 10);
          if (periodNumber > (groupClass.periods.length - 1)) {
            newErrors = newErrors.filter((error) => error.propertyPath !== err.propertyPath);
          }
        }
        if (err.propertyPath.match(RE_MATCH_PERIOD_ENDDATE_PATTERN)) {
          const periodNumber = parseInt(err.propertyPath.split('[')[1].split(']')[0], 10);
          groupClass.periods.forEach((period, index) => {
            if (period.endDate) newErrors = newErrors.filter((err) => err.propertyPath !== `periods[${ index }].endDate`);
          });
          if (periodNumber > (groupClass.periods.length - 1)) {
            newErrors = newErrors.filter((error) => error.propertyPath !== err.propertyPath);
          }
        }
        if (err.propertyPath.match(RE_MATCH_PERIOD_STARTDATE_PATTERN)) {
          const periodNumber = parseInt(err.propertyPath.split('[')[1].split(']')[0], 10);
          groupClass.periods.forEach((period, index) => {
            if (period.startDate) newErrors = newErrors.filter((err) => err.propertyPath !== `periods[${ index }].startDate`);
          });
          if (periodNumber > (groupClass.periods.length - 1)) {
            newErrors = newErrors.filter((error) => error.propertyPath !== err.propertyPath);
          }
        }
      });

      if (groupClass.endDate) newErrors = newErrors.filter((err) => err.propertyPath !== 'endDate');
      if (groupClass.startDate) newErrors = newErrors.filter((err) => err.propertyPath !== 'startDate');
      if (groupClass.type) newErrors = newErrors.filter((err) => err.propertyPath !== 'type');
    }
    return newErrors;
  }, [groupClass]);

  const handleSave = useCallback((e: SyntheticEvent<EventTarget>): void => {
    e.preventDefault();
    let customError: Error[] = ObjectHelpers.deepClone(errors);
    if (groupClass) {
      groupClass.coaches.map((coach, index) => {
        if (!coach.id) {
          const error = { propertyPath: `coaches[${ index }].name`, code: ERROR_IS_BLANK, message: '' };
          customError = [...customError, error];
        }
      });

      if (!groupClass.type) {
        const error = { propertyPath: 'type', code: ERROR_IS_BLANK, message:'' };
        customError = [...customError, error];
      }

      if (!groupClass.description) {
        const error = { propertyPath: 'description', code: ERROR_IS_BLANK, message:'' };
        customError = [...customError, error];
      }

      if ((groupClass.type === GROUP_CLASS_TYPE_SIEL_BLEU
        || groupClass.type === GROUP_CLASS_TYPE_BENEFICIARY
        || groupClass.type === GROUP_CLASS_TYPE_SUBSIDIZED_WITH_COST
        || groupClass.type === GROUP_CLASS_TYPE_INTERMEDIATE_WITH_COST)
        && groupClass.periods.length === 0) {
        const error = { propertyPath: 'noPeriods', code: ERROR_IS_BLANK, message:'' };
        customError = [...customError, error];
      }

      if (groupClass.periods.length > 0) {
        let previousEndDate = null;
        groupClass.periods.forEach((period, index) => {
          const amount = String(period.amount);
          if (amount === '') {
            const error = { propertyPath: `periods[${ index }].amount`, code: ERROR_IS_BLANK, message: '' };
            customError = [...customError, error];
          }

          if (previousEndDate && (period.startDate < previousEndDate)) {
            const error = { propertyPath: `periods[${ index }].startDate`, code: INVALID_DURATION, message: '' };
            customError = [...customError, error];
          }

          previousEndDate = period.endDate;
        });
      }

      customError = cleanError(customError);

      if (customError.length === 0) {
        GroupClassService.updateGroupClass(groupClass)
          .then((res) => {
            if (res) {
              Toast.success(t('groupClasses.groupClass.peoples.form.wellUpdated'));
              navigate(GROUP_CLASS.replace(':classId', String(groupClass.id)));
            }
          })
          .catch((err) => {
            if (err) {
              setErrors(err);
            }
          });
      }
      setErrors(customError);
    }
  }, [groupClass, language, t, errors, cleanError]);

  const loading = useMemo((): boolean => (
    !(!isUsersLoading && groupClass)
  ), [isUsersLoading, groupClass]);

  const addressFormatted = useMemo((): string => (
    groupClass?.address ? formatAddressToString(groupClass.address) : ''
  ), [groupClass]);

  return (
    <AdminLayout groupClass={ groupClass } activeTab={ ADMIN_HEADER_INFORMATION } preventPortraitMode isLoading={ loading } loaderType={ LOADER_TYPE_PAGE }>
      { groupClass && (
        <div className="container-group-class-detailed container">
          <div className="group-class-detailed">
            <div className="group-class-block-title">
              <div className="group-class-title">
                <h1 className="group-class-detailed-title">
                  { t('groupClasses.groupClass.navigation.infos') }
                  <span>{ t('groupClasses.groupClass.infos') }</span>
                </h1>
              </div>
            </div>
            <div className="group-class-container-white">
              <div className="edit-container">
                <form className="edit-form">
                  <div className="edit-line-buttons-for-mobile">
                    <Button
                      className="edit-cancel"
                      onClick={ handleCancel }
                      type={ buttonConstants.SECONDARY }
                    >
                      { t('common.cancel') }
                    </Button>
                    <Button
                      onClick={ handleSave }
                      type={ buttonConstants.PRIMARY }
                    >
                      { t('common.save') }
                    </Button>
                  </div>
                  <h2 className="edit-title no-margin">
                    { t('groupClasses.groupClass.detailed.general') }
                  </h2>
                  <div className="edit-two-sides">
                    <InputField
                      id="activity.name"
                      name="activity.name"
                      label={ t('groupClasses.groupClass.detailed.activity') }
                      className={ editGooglePlacesClassName }
                      value={ activityValue }
                      isDisabled
                      readOnly
                    />
                    <AdvancedSelectField
                      // Read only, cause this field is configured in Odoo
                      isDisabled={ true }
                      id="type"
                      name="type"
                      label={ t('groupClasses.groupClass.detailed.classType') }
                      options={ classTypeOptions }
                      isClearable
                      color={ errorsProps.type?.color }
                      labelProps={ errorsProps.type?.labelProps }
                      borderColor={ errorsProps.type?.borderColor }
                      helpProps={ errorsProps.type?.selectFieldHelperText }
                      className="edit-field input-edit-form type"
                      value={ classTypeOption }
                      placeholder={ t('groupClasses.groupClass.detailed.types.placeholder') }
                      onChange={ handleChangeSelect }
                      required
                      isSearchable={ false }
                    />
                  </div>
                  <div className="edit-two-sides">
                    <InputField
                      id="groupClassKey"
                      name="groupClassKey"
                      label={ t('groupClasses.groupClass.detailed.groupClassKey') }
                      className="edit-field input-edit-form"
                      value={ groupClass.groupClassKey || '' }
                      isDisabled
                      readOnly
                    />
                    <InputField
                      id="seasonKey"
                      name="seasonKey"
                      label={ t('groupClasses.groupClass.detailed.seasonKey') }
                      className="edit-field input-edit-form"
                      value={ groupClass.seasonKey }
                      isDisabled
                      readOnly
                    />
                  </div>
                  <div className="edit-two-sides">
                    <InputField
                      id="caseNumber"
                      name="caseNumber"
                      label={ t('groupClasses.groupClass.detailed.caseNumber') }
                      className="edit-field input-edit-form"
                      value={ groupClass.caseNumber || '' }
                      isDisabled
                      readOnly
                    />
                    <InputField
                      id="name"
                      name="name"
                      label={ t('groupClasses.groupClass.detailed.courseName') }
                      className="edit-field input-edit-form"
                      value={ groupClass.name }
                      isDisabled
                      readOnly
                    />
                  </div>
                  <div className="edit-two-sides">
                    <InputField
                      id="address"
                      name="address"
                      label={ t('groupClasses.groupClass.detailed.address.address') }
                      className="edit-field input-edit-form"
                      value={ addressFormatted }
                      isDisabled
                      readOnly
                    />
                    <InputField
                      id="address.complementNumber"
                      name="complementNumber"
                      label={ t('groupClasses.groupClass.detailed.complementNumber') }
                      className="edit-field"
                      value={ groupClass.address.complementNumber || '' }
                      isDisabled
                      readOnly
                    />
                  </div>
                  <div className="edit-two-sides">
                    <InputField
                      id="day"
                      name="day"
                      label={ t('groupClasses.groupClass.detailed.day') }
                      className="edit-field"
                      value={ dayValue }
                      isDisabled
                      readOnly
                    />
                    <Time
                      groupClass={ groupClass }
                    />
                  </div>
                  <div className="edit-two-sides">
                    <InputField
                      id="format"
                      name="format"
                      label={ t('groupClasses.groupClass.detailed.format') }
                      className="edit-field input-edit-form"
                      value={ groupClass.isOnline ? t('groupClasses.tag.online') : t('groupClasses.tag.inPerson') }
                      isDisabled
                      readOnly
                    />
                  </div>

                  <TextareaField
                    id="description"
                    className={ editTextareaFieldClassName }
                    classNameTextarea="textarea"
                    name="description"
                    label={ t('common.description') }
                    limitedChar={ DESCRIPTION_LENGTH_LIMIT }
                    color={ errorsProps.description?.color }
                    labelProps={ errorsProps.description?.labelTextAreaProps || { className: 'edit-label' } }
                    helpProps={ errorsProps.description?.error }
                    value={ groupClass.description }
                    onChange={ handleChange }
                    required
                  />

                  <Coaches
                    users={ users }
                    groupClass={ groupClass }
                    setGroupClass={ setGroupClass }
                    errorsProps={ errorsProps }
                  />

                  <Periods
                    errorsProps={ errorsProps }
                    groupClass={ groupClass }
                    setGroupClass={ setGroupClass }
                  />

                  <SeasonDates
                    errorsProps={ errorsProps }
                    groupClass={ groupClass }
                    setGroupClass={ setGroupClass }
                    onChange={ handleChange }
                  />

                  <Registration
                    onChange={ handleChange }
                    groupClass={ groupClass }
                    errorsProps={ errorsProps }
                    isAlreadyEdited={ isAlreadyEdited }
                  />

                  <div className="edit-line-buttons">
                    <Button
                      className="edit-cancel"
                      onClick={ handleCancel }
                      type={ buttonConstants.SECONDARY }
                    >
                      { t('common.cancel') }
                    </Button>
                    <Button
                      onClick={ handleSave }
                      type={ buttonConstants.PRIMARY }
                    >
                      { t('common.save') }
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) }
    </AdminLayout>
  );
};

export default withGroupClass(GroupClassDetailedEdit);
