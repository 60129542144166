// @flow
import { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AuthService from '@user/services/AuthService';
import Page from '_common/components/page/Page';
import Login from '@login/components/Login';
import ReturnSingleSignOn from '@login/components/ReturnSingleSignOn';
import ReturnLogoutSSO from '@login/components/ReturnLogoutSSO';
import ResetKeyRequest from '@login/components/ResetKeyRequest';
import ServiceUnavailable from '@login/components/ServiceUnavailable';
import PasswordUpdate from '@login/components/PasswordUpdate';

import GroupClasses from '@groupClass/components/GroupClasses';
import GroupClassesBeneficiary from '@groupClass/components/GroupClassesBeneficiary';
import GroupClassesAdmin from '@groupClass/components/GroupClassesAdmin';
import GroupClass from '@groupClass/components/GroupClass';
import GroupClassDetailedEdit from '@groupClass/components/Admin/GroupClassDetailedEdit';

import PageContact from '@pagecontact/components/PageContact';

import PageNotFound from '@pagenotfound/components/PageNotFound';

import SharedSessions from '@session/components/SharedSessions';

import Participants from '@participant/components/Participants';
import SearchPeople from '@search/components/SearchPeople';
import CreateParticipant from '@participant/components/CreateParticipant';
import FromSearchEditPerson from '@participant/components/FromSearchEditPerson';
import FromSearchCreatePayment from '@participant/components/FromSearchCreatePayment';
import RequestPersonEdit from '@participant/components/RequestPersonEdit';
import RequestPaymentEdit from '@participant/components/RequestPaymentEdit';
import RegisteredShow from '@participant/components/RegisteredShow';
import RegisteredEditPerson from '@participant/components/RegisteredEditPerson';
import RegisteredEditPayment from '@participant/components/RegisteredEditPayment';

import Payments from '@payment/components/Payments';
import PaymentDetailed from '@payment/components/PaymentDetailed';
import EditCheque from '@payment/components/EditCheque';
import PaymentDeposit from '@payment/components/PaymentDeposit';
import AddBankDeposit from '@payment/components/AddBankDeposit/AddBankDeposit';
import DetailBankDeposit from '@payment/components/DetailBankDeposit/DetailBankDeposit';
import AddChequesBankDeposit from '@payment/components/AddBankDeposit/AddChequesBankDeposit';
import CreateCheque from '@payment/components/CreateCheque';
import EditBankDeposit from '@payment/components/EditBankDeposit/EditBankDeposit';

import GroupClassSend from '@groupSend/components/GroupClassSend';
import GroupClassSendEmail from '@groupSend/components/GroupClassSendEmail';
import GroupClassSendSharedSession from '@groupSend/components/GroupClassSendSharedSession';
import GroupClassSendSMS from '@groupSend/components/GroupClassSendSMS';

import BeneficiaryPayment from '@payment/components/BeneficiaryPayment/BeneficiaryPayment';
import MoneticoError from '@payment/components/MoneticoError';
import MoneticoSuccess from '@payment/components/MoneticoSuccess';
import InvoiceDetails from '@payment/components/InvoiceDetails';

import People from '@root/src/People/components/People';
import PeopleDetail from '@root/src/People/components/PeopleDetail';

import { WEB_PATHS } from '@app/constants/paths';
import PeopleEdit from '@root/src/People/components/PeopleEdit';

const {
  HOME,
  GROUP_CLASSES,
  GROUP_CLASSES_BENEFICIARY,
  GROUP_CLASSES_TO_VALIDATE,
  GROUP_CLASSES_SEASON_CLOSED,
  GROUP_CLASS,
  GROUP_CLASS_EDIT,
  LOGIN,
  RESET_KEY_REQUEST,
  SERVICE_UNAVAILABLE,
  SIEL_BLEU_USER_PASSWORD_UPDATE,
  PARTICIPANTS,
  PARTICIPANTS_SEARCH,
  PARTICIPANTS_CREATE_PARTICIPANT,
  PARTICIPANTS_ADD_PERSON_BY_ID_PERSON,
  PARTICIPANTS_ADD_PERSON_BY_ID_PAYMENT,
  PARTICIPANTS_CLASS_REQUEST_PERSON,
  PARTICIPANTS_CLASS_REQUEST_PAYMENT,
  PARTICIPANTS_REGISTERED_ID,
  PARTICIPANTS_REGISTERED_EDIT_PERSON,
  PARTICIPANTS_REGISTERED_EDIT_PAYMENT,
  PAYMENTS,
  PAYMENTS_CREATE_CHEQUE,
  PAYMENTS_PAYMENTS_DETAILED,
  PAYMENTS_DETAILED_CREDITCARD_OR_CHEQUE,
  PAYMENTS_BANK_DEPOSITS,
  PAYMENTS_BANK_DEPOSITS_ADD,
  PAYMENTS_BANK_DEPOSIT_VIEW,
  PAYMENTS_BANK_DEPOSIT_ADD_CHEQUES,
  PAYMENTS_DEPOSIT_EDIT,
  PAYMENTS_CHEQUE_EDIT,
  GROUP_SEND,
  GROUP_SEND_EMAIL,
  GROUP_SEND_SMS,
  GROUP_SEND_SHARED_SESSION,
  SHARED_SESSIONS,
  BENEFICIARY_PAYMENT,
  INVOICE_DETAILS,
  MONETICO_ERROR,
  MONETICO_SUCCESS,
  PAGE_CONTACT,
  PAGE_NOT_FOUND,
  LOGIN_RETURN_SINGLE_SIGN_ON,
  LOGOUT_RETURN_SINGLE_SIGN_ON,
  PEOPLE,
  PEOPLE_DETAILS,
  PEOPLE_EDIT,
} = WEB_PATHS;

const App = (): React$Node => {
  const { t, i18n: { language } } = useTranslation();

  useEffect(() => {
    AuthService.autoLogin();
  }, []);

  useEffect(() => {
    document.title = t('title');
  }, [language]);

  return (
    <div className="app-container">
      <Page>
        <Routes>
          <Route path="/" element={ <Navigate to={ GROUP_CLASSES } /> } />
          <Route path={ HOME } element={ <Navigate to={ GROUP_CLASSES } /> } />

          <Route path={ GROUP_CLASSES_TO_VALIDATE } element={ <GroupClassesAdmin /> } />
          <Route path={ GROUP_CLASSES_SEASON_CLOSED } element={ <GroupClassesAdmin /> } />
          <Route path={ GROUP_CLASSES_BENEFICIARY } element={ <GroupClassesBeneficiary /> } />
          <Route path={ GROUP_CLASSES } element={ <GroupClasses /> } />
          <Route path={ GROUP_CLASS } element={ <GroupClass /> } />
          <Route path={ GROUP_CLASS_EDIT } element={ <GroupClassDetailedEdit /> } />

          { /* login */ }
          <Route path={ LOGIN } element={ <Login /> } />
          <Route path={ LOGIN_RETURN_SINGLE_SIGN_ON } element={ <ReturnSingleSignOn /> } />
          <Route path={ LOGOUT_RETURN_SINGLE_SIGN_ON } element={ <ReturnLogoutSSO /> } />
          <Route path={ SIEL_BLEU_USER_PASSWORD_UPDATE } element={ <PasswordUpdate /> } />

          { /* reset password */ }
          <Route path={ RESET_KEY_REQUEST } element={ <ResetKeyRequest /> } />

          { /* Service unavailable */ }
          <Route path={ SERVICE_UNAVAILABLE } element={ <ServiceUnavailable /> } />

          { /* // participant */ }
          <Route path={ PARTICIPANTS } element={ <Participants /> } />
          <Route path={ PARTICIPANTS_SEARCH } element={ <SearchPeople /> } />
          <Route path={ PARTICIPANTS_CREATE_PARTICIPANT } element={ <CreateParticipant /> } />
          <Route path={ PARTICIPANTS_ADD_PERSON_BY_ID_PERSON } element={ <FromSearchEditPerson /> } />
          <Route path={ PARTICIPANTS_ADD_PERSON_BY_ID_PAYMENT } element={ <FromSearchCreatePayment /> } />
          <Route path={ PARTICIPANTS_CLASS_REQUEST_PERSON } element={ <RequestPersonEdit /> } />
          <Route path={ PARTICIPANTS_CLASS_REQUEST_PAYMENT } element={ <RequestPaymentEdit /> } />
          <Route path={ PARTICIPANTS_REGISTERED_EDIT_PERSON } element={ <RegisteredEditPerson /> } />
          <Route path={ PARTICIPANTS_REGISTERED_EDIT_PAYMENT } element={ <RegisteredEditPayment /> } />
          <Route path={ PARTICIPANTS_REGISTERED_ID } element={ <RegisteredShow /> } />

          { /* // payment */ }
          <Route path={ PAYMENTS } element={ <Payments /> } />
          <Route path={ PAYMENTS_PAYMENTS_DETAILED } element={ <PaymentDetailed /> } />
          <Route path={ PAYMENTS_DETAILED_CREDITCARD_OR_CHEQUE } element={ <PaymentDetailed /> } />
          <Route path={ PAYMENTS_CREATE_CHEQUE } element={ <CreateCheque /> } />
          <Route path={ PAYMENTS_BANK_DEPOSITS } element={ <PaymentDeposit /> } />
          <Route path={ PAYMENTS_BANK_DEPOSITS_ADD } element={ <AddBankDeposit /> } />
          <Route path={ PAYMENTS_BANK_DEPOSIT_VIEW } element={ <DetailBankDeposit /> } />
          <Route path={ PAYMENTS_BANK_DEPOSIT_ADD_CHEQUES } element={ <AddChequesBankDeposit /> } />
          <Route path={ PAYMENTS_DEPOSIT_EDIT } element={ <EditBankDeposit /> } />
          <Route path={ PAYMENTS_CHEQUE_EDIT } element={ <EditCheque /> } />

          { /* // group send */ }
          <Route path={ GROUP_SEND } element={ <GroupClassSend /> } />
          <Route path={ GROUP_SEND_EMAIL } element={ <GroupClassSendEmail /> } />
          <Route path={ GROUP_SEND_SMS } element={ <GroupClassSendSMS /> } />
          <Route path={ GROUP_SEND_SHARED_SESSION } element={ <GroupClassSendSharedSession /> } />

          { /* // shared sessions */ }
          <Route path={ SHARED_SESSIONS } element={ <SharedSessions /> } />

          { /* // beneficiary */ }
          <Route path={ BENEFICIARY_PAYMENT } element={ <BeneficiaryPayment /> } />

          { /* // contact */ }
          <Route path={ PAGE_CONTACT } element={ <PageContact /> } />

          { /* // invoice details */ }
          <Route path={ INVOICE_DETAILS } element={ <InvoiceDetails /> } />

          { /* // monetico x2 */ }
          <Route path={ MONETICO_SUCCESS } element={ <MoneticoSuccess /> } />
          <Route path={ MONETICO_ERROR } element={ <MoneticoError /> } />

          { /* // people */ }
          <Route path={ PEOPLE } element={ <People /> } />
          <Route path={ PEOPLE_DETAILS } element={ <PeopleDetail /> } />
          <Route path={ PEOPLE_EDIT } element={ <PeopleEdit /> } />

          { /* // 404 */ }
          <Route path={ PAGE_NOT_FOUND } element={ <PageNotFound /> } />
          <Route path="*" element={ <PageNotFound /> } />
        </Routes>
      </Page>
    </div>
  );
};

export default App;
