// @flow
import type { Listener, QueryParams, RequestStatusesType } from '@core/types';
import type { GroupClassRegistered, GroupClassRegisteredPayload } from '@groupClass/types';

import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import { formatGroupClassRegisteredFromPayload } from '@groupClass/helpers/GroupClassesUtils';

import { RequestStatuses } from '@core/constants';

import { API_PATHS } from '@app/constants/paths';

export type ParticipantGroupClassesRegisteredsServiceData = {
  registereds: GroupClassRegistered[],
  isLoading: boolean,
  registeredsRequestStatus: RequestStatusesType,
};

type UpdateValues = (newRegistereds: GroupClassRegistered[], newIsLoading: boolean, requestStatusRegistereds: RequestStatusesType) => void;
type FetchAll = (beneficiaryId: number, language: string, params: QueryParams) => Promise<number>;
type OnChange = (listener: Listener) => Function;
type Trigger = () => void;

const {
  GROUP_CLASS_REGISTEREDS_PARTICIPANT,
} = API_PATHS;

let sourceAll = ApiService.createToken();

class ParticipantGroupClassesRegisteredsService {
  constructor() {
    this.eventEmitter = new EventEmitter();

    this.registereds = [];
    this.isLoading = false;
    this.registeredsRequestStatus = RequestStatuses.IDLE;
  }
  registereds: GroupClassRegistered[];
  isLoading: boolean;
  registeredsRequestStatus: RequestStatusesType;
  eventEmitter: EventEmitter;

  get registeredsValues(): GroupClassRegistered[] {
    return this.registereds;
  }
  get isLoadingValue(): boolean {
    return this.isLoading;
  }
  get registeredsRequestStatusValue(): RequestStatusesType {
    return this.registeredsRequestStatus;
  }

  // TODO: Implements getters/setters

  updateValues: UpdateValues = (newRegistereds: GroupClassRegistered[], newIsLoading: boolean, requestStatusRegistereds: RequestStatusesType): void => {
    this.registereds = newRegistereds;
    this.isLoading = newIsLoading;
    this.registeredsRequestStatus = requestStatusRegistereds;
    this.#trigger();
  };

  fetchAll: FetchAll = (participantId: number, language: string, params: QueryParams = {}): Promise<number> => {
    sourceAll.cancel();
    sourceAll = ApiService.createToken();

    this.updateValues(this.registereds, true, RequestStatuses.PENDING);

    return ApiService.request({
      method: 'get',
      url: GROUP_CLASS_REGISTEREDS_PARTICIPANT.replace(':id', String(participantId)),
      cancelToken: sourceAll.token,
      headers: {
        'Accept-Language': language,
      },
      params,
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;

          const groupClassesRegisteredData: GroupClassRegistered[] = data['hydra:member']
            .map((registered: GroupClassRegisteredPayload) => (
              formatGroupClassRegisteredFromPayload(registered)
            ));

          this.updateValues(groupClassesRegisteredData, false, RequestStatuses.SUCCEEDED);
          return data['hydra:totalItems'];
        } else {
          this.updateValues([], false, RequestStatuses.SUCCEEDED);
          return Promise.reject();
        }
      })
      .catch(() => {
        this.updateValues(this.registereds, false, RequestStatuses.FAILED);
        return Promise.reject();
      });
  };
  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      registereds: this.registereds,
      isLoading: this.isLoading,
    });
  };
}

const instance: ParticipantGroupClassesRegisteredsService = new ParticipantGroupClassesRegisteredsService();
export default instance;
