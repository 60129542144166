// @flow
import { WEB_PATHS } from '@app/constants/paths';
import i18n from '@app/i18n/i18n';
const { GROUP_CLASSES, GROUP_CLASSES_BENEFICIARY, SHARED_SESSIONS, PEOPLE } = WEB_PATHS;

export const GROUP_CLASSES_TAB_NAME = 'groupClassesSearch';
export const BENEFICIARY_TAB_NAME = 'groupClassesBeneficiary';
export const SHARED_SESSIONS_TAB_NAME = 'mySharedSessions';
export const ONLINE_COURSES_TAB_NAME = 'myOnlineCourseTab';
export const PEOPLE_TAB_NAME = 'peopleTab';

export const GROUP_CLASSES_TAB = {
  name: GROUP_CLASSES_TAB_NAME,
  translationKey: '',
  external: false,
  isActive: false,
  link: GROUP_CLASSES,
};

const { resolvedLanguage } = i18n;

export const ONLINE_COURSES_TAB = {
  name: ONLINE_COURSES_TAB_NAME,
  translationKey: 'navigation.myOnlineCourseTab',
  isActive: false,
  external: true,
  link: `${ process.env.REACT_APP_SIELBLEU_APP_URL || '' }/${ resolvedLanguage || 'fr' }/register?type=SIELBLEU`,
};

export const BENEFICIARY_TAB = {
  name: BENEFICIARY_TAB_NAME,
  translationKey: 'navigation.myGroupClasses',
  isActive: false,
  external: false,
  link: GROUP_CLASSES_BENEFICIARY,
};

export const SHARED_SESSIONS_TAB = {
  name: SHARED_SESSIONS_TAB_NAME,
  translationKey: 'navigation.mySharedSessions',
  isActive: false,
  external: false,
  link: SHARED_SESSIONS,
};

export const PEOPLE_TAB = {
  name: PEOPLE_TAB_NAME,
  translationKey: 'navigation.people',
  isActive: false,
  external: false,
  link: PEOPLE,
};
