// @flow
import type { NavigationTab } from '@shared/Navigation/NavigationPills/types';
import { WEB_PATHS } from '@app/constants/paths';

const {
  GROUP_CLASSES,
  GROUP_CLASSES_TO_VALIDATE,
  GROUP_CLASSES_SEASON_CLOSED,
} = WEB_PATHS;

export const GROUP_CLASSES_KEY = 'tab_group_classes_selected';
export const SEASON_CLOSED_KEY = 'tab_season_closed_selected';
export const TO_VALIDATE_KEY = 'tab_to_validate_selected';

export const SEASON_CLOSED_TAB: NavigationTab = {
  translationKey: 'navigation.seasonClosedGroupClasses',
  url: GROUP_CLASSES_SEASON_CLOSED,
  isSelectedKey: SEASON_CLOSED_KEY,
};
export const GROUP_CLASSES_TAB: NavigationTab = {
  translationKey: 'navigation.availableGroupClasses',
  url: GROUP_CLASSES,
  isSelectedKey: GROUP_CLASSES_KEY,
};
export const TO_VALIDATE_TAB: NavigationTab = {
  translationKey: 'navigation.toValidateGroupClasses',
  url: GROUP_CLASSES_TO_VALIDATE,
  isSelectedKey: TO_VALIDATE_KEY,
};

export const TO_VALIDATE_DISABLED_TAB: NavigationTab = {
  translationKey: 'navigation.toValidateGroupClasses',
  url: GROUP_CLASSES_TO_VALIDATE,
  isSelectedKey: TO_VALIDATE_KEY,
  isDisabled: true,
};

export const ADMIN_NAVIGATION_TABS: NavigationTab[] = [
  TO_VALIDATE_TAB,
  GROUP_CLASSES_TAB,
  SEASON_CLOSED_TAB,
];

export const GROUP_CLASSES_FILTERS = {
  ALL: null,
  ONLINE: true,
  IN_PERSON: false,
};
