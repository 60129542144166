// @flow

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SielBleuUserRoute from '@shared/ProtectedRoute/components/SielBleuUserRoute';
import { useTranslation } from 'react-i18next';
import { WEB_PATHS } from '@app/constants/paths';
import { People } from '@person/type';
import IconChevron from '@icons/components/IconChevron';
import PersonalInformation from '@shared/Personal/components/PersonalInformation';
import { PERSON_TYPE_BENEFICIARY, PERSON_TYPE_PARTICIPANT } from '@person/constants';
import Button from '@shared/Button';
import ParticipantService from '@participant/services/ParticipantService';
import BeneficiaryService from '@beneficiary/services/BeneficiaryService';
import type { ParticipantServiceData } from '@participant/services/ParticipantService';
import type { BeneficiaryServiceData } from '@beneficiary/services/BeneficiaryService';
import Loader from '@common/components/loader/Loader';
import toast from '@common/services/Toast/Toast';
import BeneficiaryGroupClassesRegisteredsService from '@beneficiary/services/BeneficiaryGroupClassesRegisteredsService';
import type {
  BeneficiaryGroupClassesRegisteredsServiceData,
} from '@beneficiary/services/BeneficiaryGroupClassesRegisteredsService';
import ParticipantGroupClassesRegisteredsService
  from '../../Participant/services/ParticipantGroupClassesRegisteredsService';
const { PEOPLE, PEOPLE_EDIT } = WEB_PATHS;

const PeopleDetail = () => {
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { peopleId, peopleType } = useParams();
  const [ people, setPeople ] = useState<People | null>(null);
  const [registered, setRegistered] = useState<any>([]);
  const isParticipant = people?.type === PERSON_TYPE_PARTICIPANT;
  const [loading, setLoading] = useState<boolean>(ParticipantService.isLoadingValue || BeneficiaryService.isLoadingValue);

  const handlePeopleState = (data: ParticipantServiceData | BeneficiaryServiceData): void => {
    const { isLoading, errors } = data;
    setLoading(isLoading);
    if (!isLoading && errors.length === 0) setPeople(data?.participant || data?.beneficiary);
    if (errors.length > 0) {
      toast.error(t('groupClasses.groupClass.peoples.form.genericError'));
      navigate(PEOPLE, { state: { keepSearch: true } });
    }
  };

  const handleRegisteredState = (data: BeneficiaryGroupClassesRegisteredsServiceData): void => {
    let groupClasses = {};
    groupClasses['groupClassesRegisteredTo'] = data?.registereds
      .filter((registered) => !registered?.unsubscribedAt)
      .filter((registered) => !registered?.groupClass.seasonClosedAt)
      .map((registered) => registered?.groupClass);
    setRegistered(groupClasses);
  };

  useEffect(() => ParticipantService.onChange(handlePeopleState), []);
  useEffect(() => BeneficiaryService.onChange(handlePeopleState), []);
  useEffect(() => BeneficiaryGroupClassesRegisteredsService.onChange(handleRegisteredState), []);
  useEffect(() => ParticipantGroupClassesRegisteredsService.onChange(handleRegisteredState), []);

  useEffect(() => {
    if (peopleId && peopleType) {
      if (peopleType === PERSON_TYPE_PARTICIPANT) {
        ParticipantService.fetchOne(peopleId, language);
        ParticipantGroupClassesRegisteredsService.fetchAll(peopleId, language);
      }

      if (peopleType === PERSON_TYPE_BENEFICIARY) {
        BeneficiaryService.fetchOne(peopleId, language);
        BeneficiaryGroupClassesRegisteredsService.fetchAll(peopleId, language);
      }
    }
  }, [peopleId, peopleType]);

  const handleEditPerson = (people: People): void => {
    navigate(PEOPLE_EDIT
      .replace(':peopleId', String(people.id))
      .replace(':peopleType', people.type),
    { state: { peopleState: people } });
  };

  return (
    <SielBleuUserRoute>
      { loading
        ? <Loader/>
        : (
          <div className="page">
            <div className="container">
              <div className="people-cta mt-5">
                <Link
                  onClick={ () => {
                    // reset state
                    setPeople(null);
                    setRegistered(null);
                  } }
                  to={ PEOPLE }
                  state={ { keepSearch: true } }
                  className="button button-tertiary button-position mb-5"
                >
                  <IconChevron className="back-icon"/>
                  { t('groupClasses.groupClass.peoples.form.cancelAddToGroupClass') }
                </Link>
              </div>
              <div className="detail-people">
                <div className="name-people is-flex is-justify-content-space-between">
                  <h1>
                    { `${ people?.firstName } ${ people?.lastName?.toUpperCase() }` }
                  </h1>
                  { isParticipant && (
                    <Button
                      onClick={ () => handleEditPerson(people) }
                      className="button button-primary button-edit"
                    >
                      { t('payment.actionEdit') }
                    </Button>
                  )
                  }
                </div>
                <div className="detail-people-container">
                  <div className="columns">
                    <div className="column is-12">
                      <div className="content-people">
                        <PersonalInformation data={ registered } user={ people }/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

    </SielBleuUserRoute>
  );
};

export default PeopleDetail;
