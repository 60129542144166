// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

type Props = {
  className: string,
  onClick: () => void,
};

const IconBars = (props: Props): React$Node => {
  const {
    className,
    onClick,
  } = props;

  const iconBurgerMenuClassName = useMemo((): string => clsx({
    'icon': true,
    [String(className)]: !!className,
  }), [className]);

  const newProps = {
    className: iconBurgerMenuClassName,
    onClick,
  };

  return (
    <svg
      fill="#000000"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      { ...newProps }
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M20,11H4c-0.6,0-1,0.4-1,1s0.4,1,1,1h16c0.6,0,1-0.4,1-1S20.6,11,20,11z M4,8h16c0.6,0,1-0.4,1-1s-0.4-1-1-1H4C3.4,6,3,6.4,3,7S3.4,8,4,8z M20,16H4c-0.6,0-1,0.4-1,1s0.4,1,1,1h16c0.6,0,1-0.4,1-1S20.6,16,20,16z"></path>
      </g>
    </svg>
  );
};

IconBars.defaultProps = {
  className: 'icon',
  onClick: () => {
  },
};

export default IconBars;
