// @flow

import { useTranslation } from 'react-i18next';
import InputField from '@common/components/input-field/InputField';
import Loader from '@common/components/loader/Loader';
import type { Person } from '@person/type';
import { PERSON_TYPE_BENEFICIARY, PERSON_TYPE_PARTICIPANT } from '@person/constants';
import { FRANCE, OPTIONS_DATE_FORMAT } from '@app/constants/constants';
import Pagination from '@shared/Pagination';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { PaginationData } from '@api/types';
import { DEFAULT_PAGE, DEFAULT_PAGINATION_DATA } from '@api/constants';
import PersonRequestService from '@person/services/PersonRequestsService';
import IconSearch from '@icons/components/IconSearch';
import type { PersonRequestsServiceData } from '@person/services/PersonRequestsService';
import { WEB_PATHS } from '../../App/constants/paths';
import SielBleuUserRoute from '@shared/ProtectedRoute/components/SielBleuUserRoute';

const People = (): React$Node => {
  const { t, i18n: { language } } = useTranslation();

  const { state: stateFromLocation } = useLocation();

  const [ paginationData, setPaginationData ] = useState<PaginationData>(DEFAULT_PAGINATION_DATA);
  const [ initialState, setInitialState ] = useState<boolean>(true);
  const [ isLoading, setLoading ] = useState<boolean>(PersonRequestService.isLoadingValue);
  const [ page, setPage ] = useState<number>(DEFAULT_PAGE);
  const [ personRequests, setPersonRequests ] = useState<People>(PersonRequestService.requestsValues);
  const [ search, setSearch ] = useState<string>('');
  const navigate = useNavigate();
  const { PEOPLE_DETAILS } = WEB_PATHS;

  const handleUpdateState = (data: PersonRequestsServiceData): void => {
    setLoading(data.isLoading);
    setPersonRequests(data.requests);
    setPaginationData(data.paginationData ? data.paginationData : DEFAULT_PAGINATION_DATA);
  };

  useEffect(() => PersonRequestService.onChange(handleUpdateState), []);

  useEffect(() => {
    const isSearchComingFromService = stateFromLocation?.keepSearch || false;
    if (isSearchComingFromService) {
      setPage(PersonRequestService.pageValue);
      setSearch(PersonRequestService.searchValue);
    } else {
      PersonRequestService.onClean();
    }
  }, [stateFromLocation]);

  const handleSearchChange = useCallback((event: SyntheticEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;

    setSearch(value);
  }, []);

  useEffect(() => {
    if (search.length >= 3) {
      const newPage = (search !== PersonRequestService.searchValue) ? 1 : page;
      PersonRequestService.fetchAll(language, {
        currentPage: newPage,
        fullName: search,
        personTypes: [PERSON_TYPE_BENEFICIARY, PERSON_TYPE_PARTICIPANT],
        locale: FRANCE,
      });
      setInitialState(false);
      setPage(newPage);
    } else if (search.length === 0) {
      setPersonRequests([]);
      setInitialState(true);
      setPage(1);
    }
  }, [page, search, language]);

  const icons = {
    rightIcon: <IconSearch />,
  };

  const handleSelectPerson = (person: Person): void => {
    navigate(PEOPLE_DETAILS
      .replace(':peopleId', String(person.id))
      .replace(':peopleType', person.type),
    );
  };

  return (
    <SielBleuUserRoute>
      <div className="page">
        <div className="people-container container">
          <div className="title-banner">
            <div className="title-banner-title">
              <h1>{ t('navigation.people') }</h1>
            </div>
          </div>
          <div className="search-people">
            <div className="search-field">
              <InputField
                name="search"
                value={ search }
                onChange={ handleSearchChange }
                icons={ icons }
                placeholder={ t('groupClasses.groupClass.peoples.search.placeholderSearch') }
              />
            </div>

            <div className="">
              <div className="">
                <table className="table-results">
                  <thead className="table-results-head">
                    <tr>
                      <th className="table-results-head-cell">{ t('people.hasAccount') }</th>
                      <th className="table-results-head-cell">
                        { t('groupClasses.groupClass.peoples.form.peopleFirstName') }
                      </th>
                      <th className="table-results-head-cell">
                        { t('groupClasses.groupClass.peoples.form.peopleLastName') }
                      </th>
                      <th className="table-results-head-cell">{ t('user.birthDate') }</th>
                      <th className="table-results-head-cell">
                        { t('groupClasses.groupClass.detailed.address.postalCode') }
                      </th>
                      <th className="table-results-head-cell">{ t('login.email') }</th>
                    </tr>
                  </thead>
                  <tbody className="table-results-body">
                    { initialState && (
                      <tr className="table-results-body-row">
                        <td className="table-results-body-row-cell" colSpan={ 7 }>
                          { t('groupClasses.groupClass.peoples.search.initialSearchText') }
                        </td>
                      </tr>
                    ) }

                    { isLoading && (
                      <tr className="table-results-body-row block-loader">
                        <td className="table-results-body-row-cell" colSpan={ 7 }>
                          <Loader className="table-loader" />
                        </td>
                      </tr>
                    ) }

                    { !isLoading
                    && personRequests?.map((person: Person) => (
                      <tr
                        className="table-results-body-row"
                        key={ person.id }
                        onClick={ () => handleSelectPerson(person) }
                      >
                        <td className="table-results-body-row-cell">
                          { person.type === PERSON_TYPE_BENEFICIARY ? 'Oui' : 'Non' }
                        </td>
                        <td className="table-results-body-row-cell">{ person.firstName }</td>
                        <td className="table-results-body-row-cell">
                          { person.lastName.toUpperCase() }
                        </td>
                        <td className="table-results-body-row-cell">
                          { person?.birthDate?.toLocaleDateString(language, OPTIONS_DATE_FORMAT)
                            || '' }
                        </td>
                        <td className="table-results-body-row-cell">
                          { person.address?.postalCode || '' }
                        </td>
                        <td className="table-results-body-row-cell">{ person.email }</td>
                      </tr>
                    )) }

                    { !isLoading && !initialState && personRequests?.length === 0 && (
                      <tr className="table-results-body-row">
                        <td className="table-results-body-row-cell" colSpan={ 7 }>
                          { t('groupClasses.groupClass.peoples.search.noResultText') }
                        </td>
                      </tr>
                    ) }
                  </tbody>
                </table>
              </div>

              { paginationData?.lastPage > 1 && (
                <div className="pagination-block">
                  <Pagination
                    changePage={ setPage }
                    currentPage={ paginationData.currentPage }
                    lastPage={ paginationData.lastPage }
                    alignment="is-centered"
                    isRounded
                  />
                </div>
              ) }
            </div>
          </div>
        </div>
      </div>
    </SielBleuUserRoute>
  );
};

export default People;
