// @flow
import { useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import type { GroupClass, ShortGroupClass } from '@groupClass/types';
import DateService from '_common/services/DateService';
import { uppercaseFirst } from '_common/services/CommonUtils';

import Button, { buttonConstants } from '@shared/Button';
import Image from '@shared/Image';
import CardItemPaymentStatus from '@payment/components/CardItemPaymentStatus';

import type { Coach } from '@coach/types';

import { WEB_PATHS } from '@app/constants/paths';
import IconCamera from '@icons/components/IconCamera';
import IconGroup from '@icons/components/IconGroup';
const { GROUP_CLASS } = WEB_PATHS;

type Props = {
  groupClass: GroupClass | ShortGroupClass,
  paymentStatus: string | null,
  onClick: () => void,
};

const CardItem = (props: Props): React$Node => {
  const { groupClass, paymentStatus, onClick } = props;
  const { id, startTime, endTime, day, activity, address, coaches, isOnline } = groupClass;
  const { t, i18n: { language } } = useTranslation();

  const cardItemClassName = useMemo((): string => clsx({
    'group-class-card-item': true,
    'group-class-card-seasonClosed': !!groupClass.seasonClosedAt,
    'group-class-card-hide-image': isMobile,
  }), [groupClass.seasonClosedAt]);

  const mainCoach = useMemo((): Coach | null => (
    coaches.find((coach) => coach.isMainCoach) ?? null
  ), [coaches]);

  const mainCoachName = useMemo((): string => (
    mainCoach?.sielBleuUser.firstName || ''
  ), [mainCoach]);

  return (
    <div className={ cardItemClassName } onClick={ onClick }>
      <div>
        <Image className="thumbnail group-class-card-image" src={ activity.photo } alt={ activity.name } />
      </div>
      <div className="text-column">
        {
          <div className="group-class-card-item-category">
            <span className="icon-text">
              <span className="icon">
                { isOnline ? <IconCamera/> : <IconGroup/> }
              </span>
              <span>{ isOnline ? t('groupClasses.tag.online') : t('groupClasses.tag.inPerson') }</span>
            </span>
          </div>
        }
        <Link to={ GROUP_CLASS.replace(':classId', String(id)) }>
          <p className="group-class-card-item-name">
            { activity.name }
          </p>
        </Link>
        <p className={ clsx('group-class-card-item-address', { 'hide': isOnline }) }>
          { `${ address.postalCode } ${ uppercaseFirst(address.city.toLocaleLowerCase()) }` }
        </p>
        <p className="group-class-card-item-date">
          { DateService.localDateAndTimeDisplay(day, startTime, endTime, language) }
        </p>
        <div className="group-class-card-item-infos">
          <p className="group-class-card-item-coach">
            { mainCoach && (
              <>
                { t('common.with') }
                &nbsp;
                <span className="group-class-card-item-coach-name">{ mainCoachName }</span>
              </>
            ) }
          </p>
          <Button type={ buttonConstants.TERTIARY } isOutlined className="see-more">
            { t('common.seeMore') }
          </Button>
        </div>
      </div>
      <CardItemPaymentStatus paymentStatus={ paymentStatus } />
    </div>
  );
};

CardItem.defaultProps = {
  paymentStatus: null,
};

export default CardItem;
